import React, { useEffect, useState } from "react"
import { Link, Redirect, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import {
  Button,
  Form,
  FormGroup,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input
} from "reactstrap"
import Select from 'react-select';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { useForm } from "react-hook-form"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import cellEditFactory from "react-bootstrap-table2-editor"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"

import { FaEye, FaTrashAlt } from "react-icons/fa"
import ReactTooltip from "react-tooltip"

import axios from 'axios'
import { parseCookies } from "nookies"
import Loader from '../../assets/images/preloader.gif'


//Funzione controllo permessi
import { checkPermission } from "../../helpers/Permissions/check-permission"


const Customers = (props) => {
  //GET REQUEST
  const [allCustomers, viewCustomers] = useState([{}])
  const [isLoading, setLoading] = useState(false)
  const [modalAdd, setModalAdd] = useState(false)
  const [selectedCustomer, setSelectCustomer] = useState({})

  const cookies = parseCookies()
  let history = useHistory()

  const toggleAdd = () => setModalAdd(!modalAdd)

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  //Style react Select2
  const selectStyles = {
    control: styles => ({ ...styles, borderRadius: '5px'})
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/customers/fetch`, {
          headers: {
              "x-access-token": cookies.jwt
          }
        })
        viewCustomers(response.data)
        setLoading(true)
      } catch (e) {
      }
    }
    fetch()
    checkPermission(cookies.role, cookies.code, history)
  }, [])

//let history = useHistory()

const aggiornamento = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/customers/fetch`, {
      headers: {
          "x-access-token": cookies.jwt
      }
    })
    viewCustomers(response.data)
    setLoading(true)
  } catch (e) {
  }
}


 const { SearchBar } = Search
 const columns = [
   {
     dataField: "businessName",
     text: "Ragione sociale",
     sort: true,
     formatter: (cell, row) => {
       return (
         <>
          <ReactTooltip />
          <i className="bx bxs-circle" style={{color: row.enabled ? 'green' : 'lightgrey'}} data-tip={row.enabled ? 'Abilitato' : 'Disabilitato'}/>{" "}
           <span className="a-cc">{row.businessName}</span>
         </>
       )
     }
   },
   {
     dataField: "vat",
     text: "CF / P.IVA",
     sort: true,
   },
   {
     dataField: "address.street",
     text: "Indirizzo",
     formatter: (cell, row) => {
       return (
         <>
          <span>{row.address.street + ", " + row.address.civic + " - " + row.address.postalCode + " " + row.address.city + " (" + row.address.region + ")"}</span>
         </>
       )
     }
   },
   {
     dataField: "",
     text: "Opzioni",
     formatter: (cell, row) => {
       return (
         <>
          <ReactTooltip />
          <button className="table-button-cc" data-tip="Vedi" onClick={() => history.push(`/customers/${row.code}`)}><FaEye /></button>
         </>
       )
     }
   }
 ]

 const [errVat, setErrVat] = useState("")


 const addCustomer = data => {
   const fetch = async () => {
     try {
       const response = await axios.get(`${process.env.REACT_APP_API}/customers/vat`, {
         params: {
           vat: data.vat
         },
         headers: {
             "x-access-token": cookies.jwt
         }
       })
       if(response.data.length > 0) {
         setErrVat('Partita IVA o codice fiscale già registrata')
       } else {
         setErrVat("")

         const submit = async () => {
           try {
             const add = await axios.post(`${process.env.REACT_APP_API}/customers/create`, {
               data: data
             }, {
               headers: {
                   "x-access-token": cookies.jwt
               }
             })
             toggleAdd()
             aggiornamento()
           } catch (e) {
           }
         }
         submit()

       }
     } catch (e) {
     }
   }
   fetch()
 }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        {isLoading ? (
                          <ToolkitProvider
                            keyField="ID"
                            data={allCustomers}
                            columns={columns}
                            search
                          >
                            {props => (
                              <div>
                                <div className="d-flex flex-row justify-content-between w-100 mb-2">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Cerca"
                                    className="leonardo-rounded"
                                  />
                                  <div className="text-sm-right">
                                      <Button
                                        type="button"
                                        className="button-cc-2"
                                        onClick={toggleAdd}
                                      >
                                        <i className="mdi mdi-plus mr-1" />
                                        Nuovo cliente
                                      </Button>
                                  </div>
                                </div>
                                <BootstrapTable
                                  {...props.baseProps}
                                  striped
                                  hover
                                  condensed
                                  keyField="vat"
                                  pagination={paginationFactory({
                                    showTotal: false,
                                    firstPageText: "Inizio", // the text of first page button
                                    prePageText: "Prec", // the text of previous page button
                                    nextPageText: "Succ", // the text of next page button
                                    lastPageText: "Fine", // the text of last page button
                                    nextPageTitle: "Vai al successivo", // the title of next page button
                                    prePageTitle: "Vai al precedente", // the title of previous page button
                                    firstPageTitle: "Vai al primo", // the title of first page button
                                    lastPageTitle: "Vai all'ultmo", // the title of last page button
                                  })}

                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        ) : (
                          <center>
                            <img
                              src={Loader}
                              alt="loader"
                            ></img>
                          </center>
                        )}
                      </Col>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Modale per aggiunta device*/}
        <Modal
          isOpen={modalAdd}
        >
        <Form className="form-horizontal"
          onSubmit={handleSubmit(addCustomer)}
        >
          <ModalHeader toggle={toggleAdd}>
            <h4>Nuovo cliente</h4>
          </ModalHeader>
          <ModalBody>
              <div className="mb-3">
                <Label className="form-label label-cc">Ragione sociale</Label>
                <input
                  className="form-control"
                  placeholder="es. Mario Rossi SRL"
                  type="text"
                  {... register("businessName", { required: true })}
                  required
                />
              </div>
              <div className="mb-3">
                <Label className="form-label label-cc">Cod. fiscale / P.IVA</Label>
                <input
                  className="form-control"
                  placeholder="es. 1234567890"
                  type="text"
                  {... register("vat", { required: true, maxLength: 16 })}
                  required
                />
                {errVat.length > 0 ? <p style={{color: 'red'}}>{errVat}</p> : null}
              </div>
              <Row>
                <Col md="10">
                  <div className="mb-3">
                    <Label className="form-label label-cc">Indirizzo</Label>
                    <input
                      className="form-control"
                      placeholder="es. Via Roma"
                      type="text"
                      {... register("address", { required: true, maxLength: 20 })}
                      required
                    />
                  </div>
                </Col>
                <Col md="2">
                  <div className="mb-3">
                    <Label className="form-label label-cc">Civico</Label>
                    <input
                      className="form-control"
                      placeholder="es. 1"
                      type="text"
                      {... register("civic", { required: true })}
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <div className="mb-3">
                    <Label className="form-label label-cc">CAP</Label>
                    <input
                      className="form-control"
                      placeholder="es. 10100"
                      type="text"
                      maxLength="5"
                      {... register("postalCode", { required: true})}
                      required
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label label-cc">Città</Label>
                    <input
                      className="form-control"
                      placeholder="es. Roma"
                      type="text"
                      {... register("city", { required: true})}
                      required
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label className="form-label label-cc">Provincia</Label>
                    <input
                      className="form-control"
                      placeholder="es. RM"
                      type="text"
                      maxLength="2"
                      {... register("region", { required: true, maxLength: 2 })}
                      required
                    />
                  </div>
                </Col>
              </Row>


          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleAdd} className="button-cc-secondary">
              Annulla
            </Button>
            {' '}
            <Button
              color="primary"
              type="submit"
              className="button-cc-2"
            >
              Aggiungi
            </Button>
          </ModalFooter>
          </Form>

        </Modal>
        {/* Modale per aggiunta device*/}

      </div>
    </React.Fragment>
  )
}

export default Customers
