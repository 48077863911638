import { useEffect } from 'react'
import axios from "axios"

export const checkPermission = (role, code, history) => {
  if(role !== 'ADMIN') {
    history.push(`/customers/${code}`)
  }
}

export const checkProperty = (cookies, history, location, code) => {

  if(cookies.role !== 'ADMIN') {
    if(location === "DEVICE") {
      const fetch = async () => {
        let devices = await axios.get(`${process.env.REACT_APP_API}/devices/check`, {
          params: {
            id: code,
            email: cookies.email
          },
          headers: {
              "x-access-token": cookies.jwt
          }
        })
        if(devices.data.auth === false) {
          history.push(`/customers/${cookies.code}`)
          window.location.reload()
        }
      }
      fetch()
    } else if(location === 'CUSTOMER') {
      const fetch1 = async () => {
        let customers = await axios.get(`${process.env.REACT_APP_API}/customers/check`, {
          params: {
            id: code,
            email: cookies.email
          },
          headers: {
              "x-access-token": cookies.jwt
          }
        })
        if(customers.data.auth === false) {
          history.push(`/customers/${cookies.code}`)
          window.location.reload()
        }
      }
      fetch1()

    } else if(location === 'USER') {

      const fetch2 = async () => {
        let users = await axios.get(`${process.env.REACT_APP_API}/users/check`, {
          params: {
            id: code,
            email: cookies.email
          },
          headers: {
              "x-access-token": cookies.jwt
          }
        })
        if(users.data.auth === false) {
          history.push(`/customers/${users.data.code}`)
          window.location.reload()
        }
      }
      fetch2()

    }
  }
}
