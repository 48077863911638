import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { destroyCookie } from "nookies";

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    localStorage.clear();
    destroyCookie(null, "jwt", { path: "/" });
    destroyCookie(null, "name", { path: "/" });
    destroyCookie(null, "_id", { path: "/" });
    destroyCookie(null, "email", { path: "/" });
    destroyCookie(null, "role", { path: "/" });
    history.push("/login");
  }, []);

  return <span>Logout...</span>;
};

export default Logout;
