import PropTypes from 'prop-types'
import React, { useEffect } from "react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

import { GrRaspberry } from "react-icons/gr"

//i18n
import { withTranslation } from "react-i18next"

import { parseCookies } from "nookies"

const SidebarContent = props => {
  let cookies = parseCookies()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show")

        const parent3 = parent2.parentElement

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement
          if (parent4) {
            parent4.classList.add("mm-active")
          }
        }
      }
      return false
    }
    return false
  }

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li>
            {cookies.role === 'ADMIN' ? <Link to="/" className=" waves-effect">
              <i className="bx bxs-home"></i>{" "}
              <span>Home</span>
            </Link> : <Link to={`/customers/${cookies.code}`} className=" waves-effect">
              <i className="bx bxs-home"></i>{" "}
              <span>Home</span>
            </Link>}
          </li>
          {cookies.role === 'ADMIN' ? <><li>
            <Link to="/customers" className=" waves-effect">
              <i className="bx bxs-user-pin"></i>{" "}
              <span>Clienti</span>
            </Link>
          </li>
          <li>
            <Link to="/devices" className=" waves-effect">
              <i className="bx bxs-dashboard"></i>{" "}
              <span>Dispositivi</span>
            </Link>
          </li>
          <li>
            <Link to="/users" className=" waves-effect">
              <i className="bx bx-user-circle"></i>{" "}
              <span>Utenti</span>
            </Link>
          </li></> : null}

        </ul>
      </div>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(SidebarContent))
