import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap"
import { FaCocktail } from "react-icons/fa"
import axios from "axios"
import { parseCookies } from 'nookies'
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import cellEditFactory from "react-bootstrap-table2-editor"
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import Loader from '../../assets/images/preloader.gif'
import { GrUserManager } from "react-icons/gr"
import moment from "moment"
import ReactTooltip from "react-tooltip"

//Funzione controllo permessi
import { checkPermission } from "../../helpers/Permissions/check-permission"

const Dashboard = () => {

  const [countDevice, setCountDevices] = useState(0)
  const [countCustomers, setCountCustomers] = useState(0)
  const [countUsers, setCountUsers] = useState(0)
  const [loading, setLoading] = useState(false)
  const [allLogs, viewLogs] = useState([{}])

  let cookies = parseCookies()

  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API}/devices/fetch`, {
        headers: {
          'x-access-token': cookies.jwt,
        }
      })
      const customers = await axios.get(`${process.env.REACT_APP_API}/customers/fetch`, {
        headers: {
          'x-access-token': cookies.jwt,
        }
      })
      const users = await axios.get(`${process.env.REACT_APP_API}/users/fetch`, {
        headers: {
          'x-access-token': cookies.jwt,
        }
      })
      const logs = await axios.get(`${process.env.REACT_APP_API}/devices/logs`, {
        headers: {
          'x-access-token': cookies.jwt,
        }
      })
      if(mounted) {
        setCountDevices(response.data.length)
        setCountCustomers(customers.data.length)
        setCountUsers(users.data.length)
        viewLogs(logs.data)
        setLoading(true)
      }
    }
    fetch()
    checkPermission(cookies.role, cookies.code, history)
    return(() => {
      mounted = false
    })
  }, [])

  const { SearchBar } = Search
  const { ExportCSVButton } = CSVExport;

  const columns = [
    {
      dataField: "customer",
      text: "Cliente",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
          <ReactTooltip />
          <i className="bx bxs-user-pin"/>{" "}
          <Link to={`/customers/${row.customerCode}`} className="a-cc" data-tip="Vedi cliente">{row.customer}</Link>
          </>
        )
      }
    },
    {
      dataField: "barman",
      text: "Barman",
      sort: true,
      formatter: (cell, row) => {
        return (
          <span><GrUserManager style={{color: 'lightgrey'}}/>{" "}{row.barman}</span>
        )
      }
    },
    {
      dataField: "code",
      text: "Dispositivo",
      formatter: (cell, row) => {
        return (
          <>
            <ReactTooltip />
            <i className="bx bxs-dashboard"/>{" "}
           <Link to={`/devices/${row.code}`} className="a-cc" data-tip="Vedi device">{row.name}</Link>
          </>
        )
      }
    },
    {
      dataField: "drink",
      text: "Drink",
      sort: true,
      formatter: (cell, row) => {
        return (
          <span><FaCocktail />{" "}{row.drink}</span>
        )
      }
    },
    {
      dataField: "date",
      text: "Data",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
           <span>{row.date}</span>
          </>
        )
      }
    }

  ]


    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
          {loading ?  <><Row>
                  <Col xl="4" sm="4" >
                    <Link to="/customers">
                      <Card className="shadows">
                        <CardBody style={{backgroundColor: 'crimson', color: 'white'}} >
                          <Row>
                            <Col lg="4">
                              <div className="text-lg-center">
                                <i className="icons-cc bx bxs-user-pin" style={{color: 'white'}}/>
                              </div>
                            </Col>
                            <Col lg="8">
                              <div>
                                <h1 style={{color: 'white'}}>Clienti</h1>
                                <h2 style={{color: 'white'}}>{countCustomers}</h2>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col xl="4" sm="4" >
                    <Link to="/devices">
                    <Card className="shadows">
                      <CardBody style={{backgroundColor: 'crimson', color: 'white'}} >
                        <Row>
                          <Col lg="4">
                            <div className="text-lg-center">
                              <i className="icons-cc bx bxs-dashboard" style={{color: 'white'}}/>
                            </div>
                          </Col>
                          <Col lg="8">
                            <div>
                              <h1 style={{color: 'white'}}>Dispositivi</h1>
                              <h2 style={{color: 'white'}}>{countDevice}</h2>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    </Link>
                  </Col>
                  <Col xl="4" sm="4" >
                    <Link to="/users">
                    <Card className="shadows">
                      <CardBody style={{backgroundColor: 'crimson', color: 'white'}} >
                        <Row>
                          <Col lg="4">
                            <div className="text-lg-center">
                              <i className="icons-cc bx bx-user-circle" style={{color: 'white'}}/>
                            </div>
                          </Col>
                          <Col lg="8">
                            <div>
                              <h1 style={{color: 'white'}}>Utenti</h1>
                              <h2 style={{color: 'white'}}>{countUsers}</h2>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    </Link>
                  </Col>
            </Row>
            <Row className="logs-dashboard">
            <ToolkitProvider
              keyField="ID"
              data={allLogs}
              columns={columns}
              search
            >
              {props => (
                <div>
                  <div className="d-flex flex-row justify-content-between w-100 mb-2">
                    <SearchBar
                      {...props.searchProps}
                      placeholder="Cerca"
                      className="leonardo-rounded"
                    />
                    <ReactTooltip />
                    <ExportCSVButton { ...props.csvProps }><i className="bx bxs-cloud-download font-size-24" style={{color: 'crimson'}} data-tip="Scarica CSV"/></ExportCSVButton>
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    striped
                    hover
                    condensed
                    keyField="timestamp"
                    exportCSV
                    pagination={paginationFactory({
                      showTotal: false,
                      firstPageText: "Inizio", // the text of first page button
                      prePageText: "Prec", // the text of previous page button
                      nextPageText: "Succ", // the text of next page button
                      lastPageText: "Fine", // the text of last page button
                      nextPageTitle: "Vai al successivo", // the title of next page button
                      prePageTitle: "Vai al precedente", // the title of previous page button
                      firstPageTitle: "Vai al primo", // the title of first page button
                      lastPageTitle: "Vai all'ultmo", // the title of last page button
                    })}

                  />
                </div>
              )}
            </ToolkitProvider>
            <small>* <em>per ottimizzare le performance del server vengono caricati esclusivamente gli ultimi 500 logs.</em></small>
            </Row></> : (
              <center>
                <img
                  src={Loader}
                  alt="loader"
                ></img>
              </center>
            )}

          </Container>
        </div>
      </React.Fragment>
    )
}

export default Dashboard;
