import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap"
import { FaCocktail } from "react-icons/fa"
import axios from "axios"
import { parseCookies } from 'nookies'
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import cellEditFactory from "react-bootstrap-table2-editor"
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import Loader from '../../assets/images/preloader.gif'
import not_found from '../../assets/404.png'
import { GrUserManager } from "react-icons/gr"
import moment from "moment"
import ReactTooltip from "react-tooltip"

//Funzione controllo permessi
import { checkPermission } from "../../helpers/Permissions/check-permission"

const NotFound = () => {

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <center>
              <img src={not_found} className="not-found-image"/>
              <br />
              <br />
              <br />
              <br />
              <br />
              <Link to="/" className="a-cc" style={{marginTop: '3vh'}}>Torna alla home</Link>
            </center>
          </Container>
        </div>
      </React.Fragment>
    )
}

export default NotFound;
