import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  Button,
  Form,
  FormGroup,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { useForm } from "react-hook-form";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import cellEditFactory from "react-bootstrap-table2-editor";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

import {
  FaTerminal,
  FaEye,
  FaTrashAlt,
  FaClipboardList,
  FaCog,
} from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
import { BsTrophy, BsDropletFill } from "react-icons/bs";
import { MdQueryStats, MdBlock } from "react-icons/md";
import { BiUserCircle, BiWorld } from "react-icons/bi";
import { IoBarcode } from "react-icons/io5";
import { FiClock, FiTrash2 } from "react-icons/fi";
import { SiGnubash } from "react-icons/si";
import ReactTooltip from "react-tooltip";

import ReactApexChart from "react-apexcharts";

import moment from "moment";

//Funzione controllo permessi
import { checkProperty } from "../../helpers/Permissions/check-permission";

import axios from "axios";
import { parseCookies } from "nookies";
import Loader from "../../assets/images/preloader.gif";

const DevicesProfile = (props) => {
  //GET REQUEST
  const [allDevices, viewDevices] = useState({});
  const [viewLogs, setLogs] = useState([{}]);
  const [viewDrinks, setDrinks] = useState([{}]);
  const [viewBoozes, setBoozes] = useState([{}]);
  const [viewDispensers, setDispenser] = useState([{}]);
  const [viewNames, setNames] = useState([{}]);
  const [selectedTab, setSelectedTab] = useState("DISPENSER");
  const [isLoading, setLoading] = useState(false);

  const [modalDelete, setModalDelete] = useState(false);
  const [modalBan, setModalBan] = useState(false);
  const [modalOffline, setModalOffline] = useState(false);

  const toggleDelete = () => setModalDelete(!modalDelete);
  const toggleBan = () => setModalBan(!modalBan);
  const toggleOffline = () => setModalOffline(!modalOffline);

  const cookies = parseCookies();
  let history = useHistory();

  //Style react Select2
  const selectStyles = {
    control: (styles) => ({ ...styles, borderRadius: "5px" }),
  };

  let colors = [
    "AliceBlue",
    "Aqua",
    "Aquamarine",
    "Azure",
    "Bisque",
    "Blue",
    "BlueViolet",
    "BurlyWood",
    "CadetBlue",
    "Chartreuse",
    "Coral",
    "CornflowerBlue",
    "Cornsilk",
    "Crimson",
    "Cyan",
    "DarkBlue",
    "DarkCyan",
    "DarkGoldenRod",
    "DarkGray",
    "DarkGrey",
    "DarkGreen",
    "DarkKhaki",
    "DarkMagenta",
    "DarkOliveGreen",
    "DarkOrange",
    "DarkOrchid",
    "DarkRed",
    "DarkSalmon",
    "DarkSeaGreen",
    "DarkSlateBlue",
    "DarkSlateGray",
    "DarkSlateGrey",
    "DarkTurquoise",
    "DarkViolet",
    "DeepPink",
    "DeepSkyBlue",
    "DodgerBlue",
    "FireBrick",
    "ForestGreen",
    "Fuchsia",
    "Gainsboro",
    "Gold",
    "GoldenRod",
    "Green",
    "GreenYellow",
    "HotPink",
    "IndianRed",
    "Indigo",
    "Khaki",
    "Lavender",
    "LavenderBlush",
    "LawnGreen",
    "Lime",
    "LimeGreen",
    "Linen",
    "Magenta",
    "MediumAquaMarine",
    "MediumBlue",
    "MediumOrchid",
    "MediumPurple",
    "MediumSeaGreen",
    "MediumSlateBlue",
    "MediumSpringGreen",
    "MediumTurquoise",
    "MediumVioletRed",
    "MidnightBlue",
    "Navy",
    "OldLace",
    "Olive",
    "OliveDrab",
    "Orange",
    "OrangeRed",
    "Orchid",
    "PaleGoldenRod",
    "PaleGreen",
    "PaleTurquoise",
    "PaleVioletRed",
    "PapayaWhip",
    "Peru",
    "Pink",
    "Plum",
    "PowderBlue",
    "Purple",
    "RebeccaPurple",
    "Red",
    "RosyBrown",
    "RoyalBlue",
    "SeaGreen",
    "SeaShell",
    "Sienna",
    "Silver",
    "SkyBlue",
    "SlateBlue",
    "SlateGray",
    "SlateGrey",
    "SpringGreen",
    "SteelBlue",
    "Tan",
    "Teal",
    "Thistle",
    "Tomato",
    "Turquoise",
    "Violet",
    "Yellow",
    "YellowGreen",
  ];

  const [pieSeries, setPieSeries] = useState([]);
  const [pieLabels, setPieLabels] = useState([]);
  const [pieColors, setPieColors] = useState([]);
  const [lastDays, setLastDays] = useState([]);
  const [lastMonth, setLastMonth] = useState([]);
  const [isMax, setMax] = useState(null);
  const [weekSeries, setWeekSeries] = useState([]);
  const [weekLabels, setWeekLabels] = useState([]);
  const [monthSeries, setMonthSeries] = useState([]);
  const [monthLabels, setMonthLabels] = useState([]);
  const [topSeries, setTopSeries] = useState([]);
  const [topLabels, setTopLabels] = useState([]);
  const [top3, setTop3] = useState([{}]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/devices/fetch/${props.match.params.id}`,
          {
            headers: {
              "x-access-token": cookies.jwt,
            },
          }
        );

        viewDevices(response.data);
        setLogs(response.data.logs);
        setDrinks(response.data.drinks);
        setBoozes(response.data.boozes);
        setDispenser(response.data.dispensers);

        const drinks = response.data.drinks;
        const logs = response.data.logs;
        const pie = [];

        if (drinks.length > 0) {
          drinks.map((drink) => {
            const count = logs.filter((item) => item.id === drink.id).length;
            if (count !== 0) {
              pie.push({
                name: drink.name,
                qty: count,
                color: colors[Math.floor(Math.random() * colors.length)],
              });
            }
          });

          if (pie.length > 0) {
            const max = pie.reduce(function (prev, current) {
              return prev.qty > current.qty ? prev : current;
            });

            var top3Values = pie.sort((a, b) => b.qty - a.qty).slice(0, 3);

            top3Values.map((top) => {
              setTopSeries((t) => [...t, top.qty]);
              setTopLabels((t) => [...t, top.name]);
            });

            setMax(max);
            setTop3(top3Values);

            pie.map((item) => {
              setPieSeries((pies) => [...pies, item.qty]);
              setPieLabels((piel) => [...piel, item.name]);
              setPieColors((piec) => [...piec, item.color]);
            });
          }

          //Grafico ultimi 7 giorni

          if (logs.length > 0) {
            // Inizializza un array per memorizzare i dati relativi agli ultimi 7 giorni
            const lastWeekData = [];

            // Per ciascun giorno degli ultimi 7 giorni
            for (let i = 1; i <= 7; i++) {
              // Calcola la data del giorno corrente meno i giorni passati
              const day = moment().subtract(i, "days").format("DD/MM/YYYY");

              // Controlla se ci sono dati nei logs per questo giorno
              const found = logs.filter(
                (log) =>
                  moment(log.timestamp * 1000).format("DD/MM/YYYY") === day
              );

              // Crea un oggetto che rappresenta il giorno e la quantità di dati trovati
              const dayData = {
                day: day,
                qty: found !== undefined ? found.length : 0, // Se ci sono dati trovati, la quantità è 1, altrimenti 0
              };

              // Aggiungi l'oggetto all'array dei dati degli ultimi 7 giorni
              lastWeekData.push(dayData);

              // Aggiungi il giorno corrente alla lista degli ultimi giorni (per l'etichettatura del grafico)
              setLastDays((lastDays) => [...lastDays, day]);
            }

            // Riduci l'array dei dati degli ultimi 7 giorni per ottenere la somma delle quantità per ogni giorno
            const reducedWeekData = lastWeekData.reduce(
              (accumulator, current) => {
                accumulator[current.day] =
                  (accumulator[current.day] || 0) + current.qty;
                return accumulator;
              },
              {}
            );

            // Trasforma l'oggetto ridotto in un array di oggetti
            const formattedWeekData = Object.entries(reducedWeekData).map(
              ([day, qty]) => ({
                day,
                qty,
              })
            );

            // Inverti l'ordine degli elementi nell'array
            formattedWeekData.reverse();

            // Imposta gli array dei dati e delle etichette per il grafico degli ultimi 7 giorni
            formattedWeekData.forEach((dayData) => {
              setWeekSeries((weekSeries) => [...weekSeries, dayData.qty]);
              setWeekLabels((weekLabels) => [...weekLabels, dayData.day]);
            });
          }

          // Controlla se ci sono dati nei logs
          if (logs.length > 0) {
            // Inizializza un array per memorizzare i dati relativi agli ultimi 30 giorni
            const lastMonthData = [];

            // Per ciascun giorno degli ultimi 30 giorni
            for (let j = 1; j <= 30; j++) {
              // Calcola la data del giorno corrente meno i giorni passati
              const day = moment().subtract(j, "days").format("DD/MM/YYYY");

              // Controlla se ci sono dati nei logs per questo giorno
              const found = logs.filter(
                (log) =>
                  moment(log.timestamp * 1000).format("DD/MM/YYYY") === day
              );

              // Crea un oggetto che rappresenta il giorno e la quantità di dati trovati
              const dayData = {
                day: day,
                qty: found !== undefined ? found.length : 0, // Se ci sono dati trovati, la quantità è 1, altrimenti 0
              };

              // Aggiungi l'oggetto all'array dei dati degli ultimi 30 giorni
              lastMonthData.push(dayData);

              // Aggiungi il giorno corrente alla lista degli ultimi giorni (per l'etichettatura del grafico)
              setLastMonth((lastMonth) => [...lastMonth, day]);
            }

            // Riduci l'array dei dati degli ultimi 30 giorni per ottenere la somma delle quantità per ogni giorno
            const reducedMonthData = lastMonthData.reduce(
              (accumulator, current) => {
                accumulator[current.day] =
                  (accumulator[current.day] || 0) + current.qty;
                return accumulator;
              },
              {}
            );

            // Trasforma l'oggetto ridotto in un array di oggetti
            const formattedMonthData = Object.entries(reducedMonthData).map(
              ([day, qty]) => ({
                day,
                qty,
              })
            );

            // Inverti l'ordine degli elementi nell'array
            formattedMonthData.reverse();

            // Imposta gli array dei dati e delle etichette per il grafico degli ultimi 30 giorni
            formattedMonthData.forEach((dayData) => {
              setMonthSeries((monthSeries) => [...monthSeries, dayData.qty]);
              setMonthLabels((monthLabels) => [...monthLabels, dayData.day]);
            });
          }
        }

        setLoading(true);
      } catch (e) {}
    };
    fetch();
    checkProperty(cookies, history, "DEVICE", props.match.params.id);
  }, []);

  const series = pieSeries;
  const options = {
    labels: pieLabels,
    colors: pieColors,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: "Drink",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <span>{row.name || "Non registrato"}</span>
          </>
        );
      },
    },
    {
      dataField: "",
      text: "Alcolici",
      formatter: (cell, row) => {
        return (
          <div className="progress">
            {row.boozes
              ? row.boozes.map((b, index) => {
                  return (
                    <>
                      <ReactTooltip />
                      <div
                        className="progress-bar"
                        data-tip={b.name + " : " + b.quantity}
                        role="progressbar"
                        style={{
                          width: `${(
                            (b.quantity /
                              row.boozes
                                .map((item) => item.quantity)
                                .reduce((prev, curr) => prev + curr, 0)) *
                            100
                          ).toFixed(0)}%`,
                          backgroundColor:
                            colors[Math.floor(Math.random() * colors.length)],
                        }}
                        aria-valuenow={(
                          (b.quantity /
                            row.boozes
                              .map((item) => item.quantity)
                              .reduce((prev, curr) => prev + curr, 0)) *
                          100
                        ).toFixed(0)}
                        aria-valuemin="0"
                        aria-valuemax={row.boozes
                          .map((item) => item.quantity)
                          .reduce((prev, curr) => prev + curr, 0)}
                      ></div>
                    </>
                  );
                })
              : null}
          </div>
        );
      },
    },
    {
      dataField: "barman",
      text: "Barman",
      formatter: (cell, row) => {
        return (
          <span>
            <GrUserManager style={{ color: "lightgrey" }} />{" "}
            {row.user || "Non registrato"}
          </span>
        );
      },
    },
    {
      dataField: "",
      text: "Data",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <span>
              {moment(row.timestamp * 1000).format("DD/MM/YYYY HH:mm")}
            </span>
          </>
        );
      },
    },
  ];

  const seriesC = [
    {
      name: "Totale",
      data: weekSeries,
    },
  ];
  const optionsC = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "center", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#FFF"],
      },
    },

    colors: ["#DC143C"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: weekLabels,
      position: "top",
      labels: {
        offsetY: -18,
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -35,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        },
      },
    },
    title: {
      text: "",
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
  };

  const seriesM = [
    {
      name: "Totale",
      data: monthSeries,
    },
  ];
  const optionsM = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "center", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -10,
      style: {
        fontSize: "12px",
        colors: ["#FFF"],
      },
    },
    colors: ["#DC143C"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: monthLabels,
      position: "bottom",
      labels: {
        offsetY: -25,
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -35,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        },
      },
    },
    title: {
      text: "",
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
  };

  const seriesT = [
    {
      data: topSeries,
    },
  ];
  const optionsT = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#DC143C"],
    grid: {
      borderColor: "#FFF",
    },
    xaxis: {
      categories: topLabels,
      labels: {
        offsetY: -25,
        show: false,
      },
    },
    axisBorder: {
      show: false,
      color: "#78909C",
      height: 1,
      width: "100%",
      offsetX: 0,
      offsetY: 0,
    },
    axisTicks: {
      show: false,
      borderType: "solid",
      color: "#78909C",
      height: 6,
      offsetX: 0,
      offsetY: 0,
    },
    stroke: {
      show: false,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
  };

  const { ExportCSVButton } = CSVExport;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const addDevice = (data) => {
    const fetch = async () => {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/devices/patch`,
        {
          code: props.match.params.id,
          data: data,
        },
        {
          headers: {
            "x-access-token": cookies.jwt,
          },
        }
      );
      window.location.reload();
    };
    fetch();
  };

  const deleteAccount = () => {
    const fetch = async () => {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/devices/delete/${props.match.params.id}`,
        {
          headers: {
            "x-access-token": cookies.jwt,
          },
        }
      );
      history.push("/devices");
    };
    fetch();
  };

  const banAccount = () => {
    const fetch = async () => {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/devices/block`,
        {
          id: props.match.params.id,
          paused: !allDevices.paused,
        },
        {
          headers: {
            "x-access-token": cookies.jwt,
          },
        }
      );
      window.location.reload();
    };
    fetch();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <React.Fragment>
                {isLoading ? (
                  <Row>
                    <Col md="4" xl="4">
                      <Card>
                        <CardBody>
                          <h1 className="cc-pi-profile">
                            <small>
                              <i
                                className={
                                  allDevices.online
                                    ? `bx bxs-circle blink`
                                    : `bx bxs-circle`
                                }
                                style={{
                                  color: allDevices.online ? "green" : "red",
                                }}
                              />
                              {allDevices.paused ? (
                                <i
                                  className="bx bx-pause-circle"
                                  data-tip="Ricezione dati in pausa"
                                  style={{ color: "gold" }}
                                />
                              ) : (
                                <i
                                  className="bx bx-play"
                                  data-tip="Ricezione dati attiva"
                                  style={{ color: "green" }}
                                />
                              )}
                            </small>{" "}
                            {allDevices.name}
                          </h1>
                          <br />
                          <ReactTooltip />
                          {cookies.role === "ADMIN" ? (
                            <>
                              <p className="cc-pi-info">
                                <i
                                  data-tip="Cliente"
                                  className="cc-pi-info-icon bx bxs-user-pin"
                                />{" "}
                                <Link
                                  className="a-cc"
                                  to={`/customers/${allDevices.customer.code}`}
                                >
                                  {allDevices.customer.businessName}
                                </Link>
                              </p>
                              <p className="cc-pi-info">
                                <BiWorld
                                  data-tip="Indirizzo IPv4"
                                  className="cc-pi-info-icon"
                                />{" "}
                                {allDevices.ipAddress}
                              </p>
                              <p className="cc-pi-info">
                                <IoBarcode
                                  data-tip="Seriale"
                                  className="cc-pi-info-icon"
                                />{" "}
                                {allDevices.serial}
                              </p>
                              <p className="cc-pi-info">
                                <SiGnubash
                                  data-tip="Codice script"
                                  className="cc-pi-info-icon"
                                />{" "}
                                {allDevices.code}
                              </p>
                            </>
                          ) : null}
                          <p className="cc-pi-info">
                            <FiClock
                              data-tip="Uptime"
                              className="cc-pi-info-icon"
                            />{" "}
                            {allDevices.uptime}
                          </p>
                          <br />
                          <hr />
                          <br />
                          <h3>
                            <BsTrophy /> Top drinks
                          </h3>
                          <ReactApexChart
                            options={optionsT}
                            series={seriesT}
                            type="bar"
                            height="150"
                          />
                          <br />
                        </CardBody>
                        {cookies.role === "ADMIN" ? (
                          <div
                            className={
                              selectedTab === "OPZIONI"
                                ? "cc-pi-selected"
                                : "cc-pi-select"
                            }
                            onClick={() => setSelectedTab("OPZIONI")}
                          >
                            <center>
                              <FaCog /> OPZIONI
                            </center>
                          </div>
                        ) : null}
                        <div
                          className={
                            selectedTab === "DISPENSER"
                              ? "cc-pi-selected"
                              : "cc-pi-select"
                          }
                          onClick={() => setSelectedTab("DISPENSER")}
                        >
                          <center>
                            <BsDropletFill /> DISPENSERS
                          </center>
                        </div>
                        <div
                          className={
                            selectedTab === "STATS"
                              ? "cc-pi-selected"
                              : "cc-pi-select"
                          }
                          onClick={() => setSelectedTab("STATS")}
                        >
                          <center>
                            <MdQueryStats /> STATISTICHE
                          </center>
                        </div>
                        <div
                          className={
                            selectedTab === "LOGS"
                              ? "cc-pi-selected"
                              : "cc-pi-select"
                          }
                          onClick={() => setSelectedTab("LOGS")}
                        >
                          <center>
                            <FaClipboardList /> REGISTRO
                          </center>
                        </div>
                        {cookies.role === "ADMIN" ? (
                          <>
                            {/* {allDevices.online ? (
                              <div
                                className={
                                  selectedTab === "SHELL"
                                    ? "cc-pi-selected"
                                    : "cc-pi-select"
                                }
                                onClick={() => {
                                  setSelectedTab("SHELL");
                                  history.push(
                                    `/shell/${props.match.params.id}`
                                  );
                                }}
                              >
                                <center>
                                  <FaTerminal /> SHELL
                                </center>
                              </div>
                            ) : (
                              <div
                                className={
                                  selectedTab === "SHELL"
                                    ? "cc-pi-selected"
                                    : "cc-pi-select"
                                }
                                onClick={() => toggleOffline()}
                              >
                                <center>
                                  <FaTerminal /> SHELL
                                </center>
                              </div>
                            )} */}
                            <div
                              className={
                                selectedTab === "ELIMINA"
                                  ? "cc-pi-selected"
                                  : "cc-pi-select"
                              }
                              onClick={() => setSelectedTab("ELIMINA")}
                            >
                              <center>
                                <FaCog /> ELIMINA / BLOCCA
                              </center>
                            </div>
                          </>
                        ) : null}
                      </Card>
                    </Col>
                    {/*Grafici dispenser*/}
                    {(() => {
                      switch (selectedTab) {
                        case "OPZIONI":
                          return (
                            <Col md="8" xl="8">
                              <Row>
                                <Card>
                                  <CardBody>
                                    <Form
                                      className="form-horizontal"
                                      onSubmit={handleSubmit(addDevice)}
                                    >
                                      <Row>
                                        <Col md="12">
                                          <div className="mb-3">
                                            <Label className="form-label label-cc">
                                              Nome dispositivo
                                            </Label>
                                            <input
                                              className="form-control"
                                              placeholder="Inserisci nome dispositivo"
                                              type="text"
                                              defaultValue={allDevices.name}
                                              {...register("name")}
                                              required
                                            />
                                          </div>
                                        </Col>
                                        <Col md="12">
                                          <div className="mb-3">
                                            <Label className="form-label label-cc">
                                              Seriale
                                            </Label>
                                            <input
                                              className="form-control"
                                              placeholder="Inserisci seriale"
                                              type="text"
                                              defaultValue={allDevices.serial}
                                              {...register("serial")}
                                              required
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Button
                                        type="reset"
                                        className="button-cc-secondary"
                                      >
                                        Annulla
                                      </Button>{" "}
                                      <Button
                                        color="primary"
                                        type="submit"
                                        className="button-cc-2"
                                      >
                                        Modifica
                                      </Button>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </Row>
                            </Col>
                          );
                        case "DISPENSER":
                          return (
                            <Col md="8" xl="8">
                              <Row>
                                {viewDispensers.length > 0
                                  ? viewDispensers.map((dispenser, index) => {
                                      return (
                                        <Col md="4" key={index}>
                                          <Card>
                                            <CardBody>
                                              <center>
                                                <h1>{index + 1}</h1>
                                              </center>
                                              <ReactApexChart
                                                options={{
                                                  chart: {
                                                    height: 300,
                                                    type: "radialBar",
                                                  },
                                                  plotOptions: {
                                                    radialBar: {
                                                      hollow: {
                                                        size: "70%",
                                                      },
                                                    },
                                                  },
                                                  labels: [
                                                    viewBoozes.find(
                                                      (x) =>
                                                        x.id ===
                                                        dispenser.booze_id
                                                    )?.name || "Vuoto",
                                                  ],
                                                  colors: ["#DC143C"],
                                                }}
                                                series={[
                                                  ((viewBoozes.find(
                                                    (x) =>
                                                      x.id ===
                                                      dispenser.booze_id
                                                  )?.quantity || 1000) !== 0
                                                    ? Math.min(
                                                        ((dispenser.quantity ||
                                                          0) /
                                                          (viewBoozes.find(
                                                            (x) =>
                                                              x.id ===
                                                              dispenser.booze_id
                                                          )?.quantity ||
                                                            1000)) *
                                                          100,
                                                        100
                                                      )
                                                    : 100
                                                  ).toFixed(0),
                                                  // (((dispenser.quantity || 0) / (viewBoozes.find((x) => x.id === dispenser.booze_id)?.quantity || 1000)) * 100).toFixed(0)
                                                ]}
                                                type="radialBar"
                                                height="200"
                                              />
                                           </CardBody>
                                            <CardFooter>
                                              <center>
                                                Rimangono {dispenser.quantity || 0} ml
                                              </center>
                                            </CardFooter>
                                          </Card>
                                        </Col>
                                      );
                                    })
                                  : null}
                              </Row>
                            </Col>
                          );
                        case "STATS":
                          return (
                            <Col md="8" xl="8">
                              <Card>
                                <CardBody>
                                  <h1>Totale drinks: {viewLogs.length}</h1>
                                  <ReactApexChart
                                    options={options}
                                    series={series}
                                    type="pie"
                                    height="380"
                                  />
                                  <h1>Ultimi 7 giorni</h1>
                                  <ReactApexChart
                                    options={optionsC}
                                    series={seriesC}
                                    type="bar"
                                    height={350}
                                  />
                                  <h1>Ultimi 30 giorni</h1>
                                  <ReactApexChart
                                    options={optionsM}
                                    series={seriesM}
                                    type="bar"
                                    height={450}
                                  />
                                </CardBody>
                              </Card>
                            </Col>
                          );
                        case "LOGS":
                          return (
                            <Col md="8" xl="8">
                              <Card>
                                <CardBody>
                                  <ToolkitProvider
                                    keyField="id"
                                    data={viewLogs}
                                    columns={columns}
                                    search
                                  >
                                    {(props) => (
                                      <div>
                                        <div className="d-flex flex-row justify-content-between w-100 mb-2">
                                          <SearchBar
                                            {...props.searchProps}
                                            placeholder="Cerca"
                                            className="leonardo-rounded"
                                          />
                                          {/*<ExportCSVButton { ...props.csvProps }><i className="bx bxs-cloud-download font-size-24" style={{color: 'crimson'}} data-tip="Scarica CSV"/></ExportCSVButton>*/}
                                        </div>
                                        <BootstrapTable
                                          {...props.baseProps}
                                          hover
                                          condensed
                                          keyField="ID"
                                          pagination={paginationFactory({
                                            showTotal: false,
                                            firstPageText: "Inizio", // the text of first page button
                                            prePageText: "Prec", // the text of previous page button
                                            nextPageText: "Succ", // the text of next page button
                                            lastPageText: "Fine", // the text of last page button
                                            nextPageTitle: "Vai al successivo", // the title of next page button
                                            prePageTitle: "Vai al precedente", // the title of previous page button
                                            firstPageTitle: "Vai al primo", // the title of first page button
                                            lastPageTitle: "Vai all'ultmo", // the title of last page button
                                          })}
                                        />
                                      </div>
                                    )}
                                  </ToolkitProvider>
                                </CardBody>
                              </Card>
                            </Col>
                          );
                        case "ELIMINA":
                          return (
                            <Col md="8" xl="8">
                              <Row>
                                <Card>
                                  <CardBody>
                                    <p
                                      className="a-cc-ban-delete"
                                      onClick={() => toggleBan()}
                                    >
                                      <MdBlock /> Clicca qui per{" "}
                                      {allDevices.paused
                                        ? "sbloccare"
                                        : "bloccare"}{" "}
                                      la ricezione dati
                                    </p>
                                    <br />
                                    <p
                                      className="a-cc-ban-delete"
                                      onClick={() => toggleDelete()}
                                    >
                                      <FiTrash2 /> Clicca qui per eliminare il
                                      dispositivo
                                    </p>
                                  </CardBody>
                                </Card>
                              </Row>
                            </Col>
                          );
                      }
                    })()}
                    {/*Grafici dispenser*/}
                  </Row>
                ) : null}
              </React.Fragment>
            </Col>
          </Row>
        </Container>

        {/* Modale per eliminazione cliente*/}
        <Modal isOpen={modalDelete}>
          <ModalHeader toggle={toggleDelete}>
            <h4>Elimina</h4>
          </ModalHeader>
          <ModalBody>
            <p>
              Stai per eliminare il dispositivo, i suoi dati e i suoi logs, sei
              sicuro di voler procedere?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleDelete} className="button-cc-secondary">
              Annulla
            </Button>{" "}
            <Button
              color="primary"
              className="button-cc-2"
              onClick={() => deleteAccount()}
            >
              Prosegui
            </Button>
          </ModalFooter>
        </Modal>
        {/* Modale per connessione device*/}

        {/* Modale per connessione device*/}
        <Modal isOpen={modalBan}>
          <ModalHeader toggle={toggleBan}>
            <h4>Ban</h4>
          </ModalHeader>
          <ModalBody>
            <p>
              Stai per {allDevices.paused ? "eseguire" : "rimuovere"} il blocco
              temporaneo alla ricezione dati, sei sicuro di voler procedere?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleBan} className="button-cc-secondary">
              Annulla
            </Button>{" "}
            <Button
              color="primary"
              className="button-cc-2"
              onClick={() => banAccount()}
            >
              Prosegui
            </Button>
          </ModalFooter>
        </Modal>
        {/* Modale per connessione device*/}

        {/* Modale per connessione device*/}
        <Modal isOpen={modalOffline}>
          <ModalHeader toggle={toggleOffline}>
            <h4>Ban</h4>
          </ModalHeader>
          <ModalBody>
            <p>Il device è offline, non puoi effettuare la connessione.</p>
          </ModalBody>
          <ModalFooter>
            {" "}
            <Button
              color="primary"
              className="button-cc-2"
              onClick={() => toggleOffline()}
            >
              Continua
            </Button>
          </ModalFooter>
        </Modal>
        {/* Modale per connessione device*/}
      </div>
    </React.Fragment>
  );
};

export default DevicesProfile;
