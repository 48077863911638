import React, { useEffect, useState } from "react"
import { Link, Redirect, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import {
  Button,
  Form,
  FormGroup,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input
} from "reactstrap"
import Select from 'react-select';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { useForm } from "react-hook-form"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import cellEditFactory from "react-bootstrap-table2-editor"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"

import { FaTerminal, FaEye, FaTrashAlt } from "react-icons/fa"
import ReactTooltip from "react-tooltip"

import axios from 'axios'
import { parseCookies } from "nookies"
import Loader from '../../assets/images/preloader.gif'
import io from "socket.io-client";
import stripAnsi from 'strip-ansi';


import Terminal from 'react-bash';


//Funzione controllo permessi
import { checkPermission } from "../../helpers/Permissions/check-permission"


const ENDPOINT = "https://socket.tribusadv.com";

const socket = io.connect(ENDPOINT)

const generate_random = (length) => {
      var result           = [];
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() *
   charactersLength)));
     }
     return result.join('');
}


const DevicesShell = (props) => {
  //GET REQUEST
  const [allDevices, viewDevices] = useState([{}])
  const [allCustomers, viewCustomers] = useState([{}])
  const [isLoading, setLoading] = useState(false)
  const [modalAdd, setModalAdd] = useState(false)
  const [modalSSH, setModalSSH] = useState(false)
  const [selectedCustomer, setSelectCustomer] = useState(null)
  const [err, setErr] = useState("")
  const [selectedDevice, setSelectedDevice] = useState(null)
  const [room, setRoom] = useState('')

  const cookies = parseCookies()
  let history = useHistory()

  const toggleAdd = () => setModalAdd(!modalAdd)
  const toggleSSH = () => setModalSSH(!modalSSH)
  const prepareSSH = value => {
    setSelectedDevice(value)
    toggleSSH()
  }

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  //Style react Select2
  const selectStyles = {
    control: styles => ({ ...styles, borderRadius: '5px'})
  }

  const [responx, setResponse] = useState("");
  const [viewHistory, setHistory] = useState([])
  const [command, setCommand] = useState("")
  const [username, setUsername] = useState("")
  const [io, setIO] = useState(null)

  function arrayBufferToString(buffer){
    var arr = new Uint8Array(buffer);
    var str = String.fromCharCode.apply(String, arr);
    if(/[\u0080-\uffff]/.test(str)){
        throw new Error("this string seems to contain (still encoded) multibytes");
    }
    return str;
}

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/devices/fetch/${props.match.params.id}`, {
          headers: {
              "x-access-token": cookies.jwt
          }
        })
        if(response.data.online) {
          let newRoom = generate_random(15)
          await socket.emit("join_room", newRoom)
          await socket.emit("start_shell", {
            room: newRoom,
            key: process.env.REACT_APP_SHELL_TOKEN
          })
          await socket.emit("send_ip_address", response.data.ipAddress)
          viewDevices(response.data)
          setRoom(newRoom)
          setLoading(true)
        }
      } catch (e) {
      }
    }
    fetch()
    checkPermission(cookies.role, cookies.code, history)

  }, [])


  useEffect(() => {
    socket.on("recieve_message", (data) => {
      if(typeof data === 'string') {
        setHistory((history) => [...history, data])
        if(data === 'Stream :: close') {
          setTimeout(() => {
            history.push("/devices")
          }, 3000)
        }
      } else if(typeof data === 'object') {
        data = arrayBufferToString(data)
        setHistory((history) => [...history, stripAnsi(data)])
        let split = data.split("@")
        if(split[0] === 'connection' || split[0] === 'root') {
          setUsername(data)
        }
      }
    })
  }, [socket])

  const addInput = async () => {
    if(command !== "") {
      //setHistory((history) => [...history, command])
      setCommand("")
      await socket.emit('command', command)
      if(command === 'reboot' && username.slice(-3) === "~# ") {
        setTimeout(() => {
          history.push("/devices")
        }, 3000)

      }
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="terminal">
          {viewHistory.map((item, index) => {
            return (
              <>{index !== 0 && (viewHistory[index - 1] !== item) ? <div id="history" key={index} style={{align: 'left'}}>{item}</div> : null}</>
            )
          })}
            <div className="line">
              <span id="path">{username}</span>
              <input type="text" value={command}
              onChange={e => setCommand(e.target.value)}
              placeholder="Enter command..."
              onKeyPress={event => {
                if(event.key === 'Enter') {
                  addInput()
                }
              }}
              />
            </div>
          </div>
        </Container>


      </div>
    </React.Fragment>
  )
}

export default DevicesShell
