import React, { useEffect, useState } from "react"
import { Link, Redirect, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import {
  Button,
  Form,
  FormGroup,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input
} from "reactstrap"
import Select from 'react-select';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { useForm } from "react-hook-form"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import cellEditFactory from "react-bootstrap-table2-editor"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"

import { FaTerminal, FaEye, FaTrashAlt } from "react-icons/fa"
import ReactTooltip from "react-tooltip"

import axios from 'axios'
import { parseCookies } from "nookies"
import Loader from '../../assets/images/preloader.gif'


//Funzione controllo permessi
import { checkPermission } from "../../helpers/Permissions/check-permission"

const Users = (props) => {
  //GET REQUEST
  const [allUsers, viewUsers] = useState([{}])
  const [allCustomers, viewCustomers] = useState([{}])
  const [isLoading, setLoading] = useState(false)
  const [modalAdd, setModalAdd] = useState(false)
  const [modalAdd2, setModalAdd2] = useState(false)
  const [modalSSH, setModalSSH] = useState(false)
  const [selectedCustomer, setSelectCustomer] = useState(null)
  const [err, setErr] = useState("")
  const [selectedDevice, setSelectedDevice] = useState(null)

  const cookies = parseCookies()
  let history = useHistory()

  const toggleAdd = () => setModalAdd(!modalAdd)
  const toggleAdd2 = () => setModalAdd2(!modalAdd2)
  const toggleSSH = () => setModalSSH(!modalSSH)
  const prepareSSH = value => {
    setSelectedDevice(value)
    toggleSSH()
  }

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { register:register2, handleSubmit:handleSubmit2, formState: { errors:errors2 } } = useForm();

  //Style react Select2
  const selectStyles = {
    control: styles => ({ ...styles, borderRadius: '5px'})
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/users/fetch`, {
          headers: {
              "x-access-token": cookies.jwt
          }
        })
        const customers = await axios.get(`${process.env.REACT_APP_API}/customers/fetch-select`, {
          headers: {
              "x-access-token": cookies.jwt
          }
        })
        viewUsers(response.data)
        viewCustomers(customers.data)
        setLoading(true)
      } catch (e) {
      }
    }
    fetch()
    checkPermission(cookies.role, cookies.code, history)
  }, [])

//let history = useHistory()

const aggiornamento = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/users/fetch`, {
      headers: {
          "x-access-token": cookies.jwt
      }
    })
    const customers = await axios.get(`${process.env.REACT_APP_API}/customers/fetch-select`, {
      headers: {
          "x-access-token": cookies.jwt
      }
    })
    viewUsers(response.data)
    viewCustomers(customers.data)
    setLoading(true)
  } catch (e) {
  }
}


 const { SearchBar } = Search
 const columns = [
   {
     dataField: "fname",
     text: "Nome",
     sort: true,
     formatter: (cell, row) => {
       return (
         <>
         <ReactTooltip />
         <span>{row.role === "ADMIN" ? <i className="bx bxs-star" style={{color: 'gold'}} data-tip="Admin"/> : <i className="bx bx-user-circle" data-tip="User" />}{" "}{row.fname.toUpperCase() + " " + row.lname.toUpperCase()}</span>
         </>
       )
     }
   },
   {
     dataField: "email",
     text: "Email",
     sort: true
   },
   {
     dataField: "customer",
     text: "Cliente",
     sort: true,
     formatter: (cell, row) => {
       return (
         <>{row.code ? <Link to={`/customers/${row.code}`} className="a-cc">{row.customer}</Link> : ''}</>
       )
     }
   },
   {
     dataField: "",
     text: "Opzioni",
     formatter: (cell, row) => {
       return (
         <>
          <ReactTooltip />
          <button className="table-button-cc" data-tip="Vedi" onClick={() => history.push(`/users/${row.token}`)}><FaEye /></button>
         </>
       )
     }
   }
 ]


 const addDevice = data => {
     setErr("")
       const fetch = async () => {
         try {
           const response = await axios.get(`${process.env.REACT_APP_API}/users/email`, {
             params: {
               email: data.email
             },
             headers: {
                 "x-access-token": cookies.jwt
             }
           })
           if(response.data.length > 0) {
             setErr('É già presente un account associato a questa mail.')
           } else {
             const submit = async () => {
               try {
                 const add = await axios.post(`${process.env.REACT_APP_API}/auth/signup`, {
                   data: data,
                   customer: selectedCustomer,
                   role: 'USER'
                 }, {
                   headers: {
                       "x-access-token": cookies.jwt
                   }
                 })
                 toggleAdd()
                 setSelectCustomer(null)
                 aggiornamento()
               } catch (e) {
               }
             }
             submit()
           }
         } catch (e) {
         }
       }
       fetch()
 }

 const addDevice2 = data => {
     setErr("")
       const fetch = async () => {
         try {
           const response = await axios.get(`${process.env.REACT_APP_API}/users/email`, {
             params: {
               email: data.email
             },
             headers: {
                 "x-access-token": cookies.jwt
             }
           })
           if(response.data.length > 0) {
             setErr('É già presente un account associato a questa mail.')
           } else {
             const submit = async () => {
               try {
                 const add = await axios.post(`${process.env.REACT_APP_API}/auth/signup`, {
                   data: data,
                   customer: selectedCustomer,
                   role: 'ADMIN'
                 }, {
                   headers: {
                       "x-access-token": cookies.jwt
                   }
                 })
                 toggleAdd2()
                 aggiornamento()
               } catch (e) {
               }
             }
             submit()

           }
         } catch (e) {
         }
       }
       fetch()
 }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        {isLoading ? (
                          <ToolkitProvider
                            keyField="_id"
                            data={allUsers}
                            columns={columns}
                            search
                          >
                            {props => (
                              <div>
                                <div className="d-flex flex-row justify-content-between w-100 mb-2">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Cerca"
                                    className="leonardo-rounded"
                                  />
                                  <div className="text-sm-right">
                                      <Button
                                        type="button"
                                        className="button-cc-2 mr-2"
                                        onClick={toggleAdd2}
                                      >
                                        <i className="mdi mdi-plus mr-1" />
                                        Nuovo admin
                                      </Button>

                                      <Button
                                        type="button"
                                        className="button-cc-2"
                                        onClick={toggleAdd}
                                      >
                                        <i className="mdi mdi-plus mr-1" />
                                        Nuovo utente
                                      </Button>
                                  </div>
                                </div>
                                <BootstrapTable
                                  {...props.baseProps}
                                  hover
                                  condensed
                                  keyField="ID"
                                  pagination={paginationFactory({
                                    showTotal: false,
                                    firstPageText: "Inizio", // the text of first page button
                                    prePageText: "Precedente", // the text of previous page button
                                    nextPageText: "Successivo", // the text of next page button
                                    lastPageText: "Fine", // the text of last page button
                                    nextPageTitle: "Vai al successivo", // the title of next page button
                                    prePageTitle: "Vai al precedente", // the title of previous page button
                                    firstPageTitle: "Vai al primo", // the title of first page button
                                    lastPageTitle: "Vai all'ultimo", // the title of last page button
                                  })}

                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        ) : (
                          <center>
                            <img
                              src={Loader}
                              alt="loader"
                            ></img>
                          </center>
                        )}
                      </Col>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Modale per aggiunta utente*/}
        <Modal
          isOpen={modalAdd}
        >
        <Form className="form-horizontal"
          onSubmit={handleSubmit(addDevice)}
        >
          <ModalHeader toggle={toggleAdd}>
            <h4>Nuovo utente</h4>
          </ModalHeader>
          <ModalBody>
              <div className="mb-3">
                <Label className="form-label label-cc">Cliente</Label>
                <Select
                  placeholder="Seleziona cliente"
                  options={allCustomers}
                  styles={selectStyles}
                  onChange={(e) => setSelectCustomer(e)}
                />
              </div>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label label-cc">Nome</Label>
                    <input
                      className="form-control"
                      placeholder="es. Mario"
                      type="text"
                      {... register("fname")}
                      required
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label label-cc">Cognome</Label>
                    <input
                      className="form-control"
                      placeholder="es. Rossi"
                      type="text"
                      {... register("lname")}
                      required
                    />
                  </div>
                </Col>
              </Row>
              <div className="mb-3">
                <Label className="form-label label-cc">Email</Label>
                <input
                  className="form-control"
                  placeholder="es. mario@connectioncocktails.com"
                  type="email"
                  {... register("email")}
                  required
                />
              </div>
              {err ? <p style={{color: 'crimson'}}>{err}</p> : null}

          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleAdd} className="button-cc-secondary">
              Annulla
            </Button>
            {' '}
            <Button
              color="primary"
              type="submit"
              className="button-cc-2"
            >
              Aggiungi
            </Button>
          </ModalFooter>
          </Form>

        </Modal>
        {/* Modale per aggiunta utente*/}


        {/* Modale per aggiunta admin*/}
        <Modal
          isOpen={modalAdd2}
        >
        <Form className="form-horizontal"
          onSubmit={handleSubmit2(addDevice2)}
        >
          <ModalHeader toggle={toggleAdd2}>
            <h4>Nuovo admin</h4>
          </ModalHeader>
          <ModalBody>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label label-cc">Nome</Label>
                    <input
                      className="form-control"
                      placeholder="es. Mario"
                      type="text"
                      {... register2("fname")}
                      required
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label label-cc">Cognome</Label>
                    <input
                      className="form-control"
                      placeholder="es. Rossi"
                      type="text"
                      {... register2("lname")}
                      required
                    />
                  </div>
                </Col>
              </Row>
              <div className="mb-3">
                <Label className="form-label label-cc">Email</Label>
                <input
                  className="form-control"
                  placeholder="es. mario@connectioncocktails.com"
                  type="email"
                  {... register2("email")}
                  required
                />
              </div>
              {err ? <p style={{color: 'crimson'}}>{err}</p> : null}

          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleAdd2} className="button-cc-secondary">
              Annulla
            </Button>
            {' '}
            <Button
              color="primary"
              type="submit"
              className="button-cc-2"
            >
              Aggiungi
            </Button>
          </ModalFooter>
          </Form>

        </Modal>
        {/* Modale per aggiunta admin*/}

      </div>
    </React.Fragment>
  )
}

export default Users
