import React, { useEffect, useState } from "react"
import { Link, Redirect, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import {
  Button,
  Form,
  FormGroup,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input
} from "reactstrap"
import Select from 'react-select';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { useForm } from "react-hook-form"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import cellEditFactory from "react-bootstrap-table2-editor"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"

import { FaTerminal, FaEye, FaTrashAlt } from "react-icons/fa"
import { MdSystemUpdateAlt } from "react-icons/md"
import ReactTooltip from "react-tooltip"

import axios from 'axios'
import { parseCookies } from "nookies"
import Loader from '../../assets/images/preloader.gif'


//Funzione controllo permessi
import { checkPermission } from "../../helpers/Permissions/check-permission"

const Devices = (props) => {
  //GET REQUEST
  const [allDevices, viewDevices] = useState([{}])
  const [allCustomers, viewCustomers] = useState([{}])
  const [isLoading, setLoading] = useState(false)
  const [modalAdd, setModalAdd] = useState(false)
  const [modalUpdate, setmodalUpdate] = useState(false)
  const [modalUpdateSuccess, setmodalUpdateSuccess] = useState(false)
  const [modalSSH, setModalSSH] = useState(false)
  const [selectedCustomer, setSelectCustomer] = useState(null)
  const [err, setErr] = useState("")
  const [selectedDevice, setSelectedDevice] = useState(null)

  const cookies = parseCookies()
  let history = useHistory()

  const toggleAdd = () => setModalAdd(!modalAdd)
  const toggleUpdate = () => setmodalUpdate(!modalUpdate)
  const toggleSuccess = () => setmodalUpdateSuccess(!modalUpdateSuccess)
  const toggleSSH = () => setModalSSH(!modalSSH)
  const prepareSSH = value => {
    setSelectedDevice(value)
    toggleSSH()
  }

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  //Style react Select2
  const selectStyles = {
    control: styles => ({ ...styles, borderRadius: '5px'})
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/devices/fetch`, {
          headers: {
              "x-access-token": cookies.jwt
          }
        })
        const customers = await axios.get(`${process.env.REACT_APP_API}/customers/fetch-select`, {
          headers: {
              "x-access-token": cookies.jwt
          }
        })
        viewDevices(response.data)
        viewCustomers(customers.data)
        setLoading(true)
      } catch (e) {
      }
    }
    fetch()
    checkPermission(cookies.role, cookies.code, history)

  }, [])

//let history = useHistory()


 const { SearchBar } = Search
 const columns = [
   {
     dataField: "name",
     text: "Nome dispositivo",
     sort: true,
     formatter: (cell, row) => {
       return (
         <>
          <ReactTooltip />
          <i className="bx bxs-circle" style={{color: row.online ? 'green' : 'red'}} data-tip={row.online ? 'Online' : 'Offline'}/>{" "}
           <span className="a-cc">{row.name}</span>
         </>
       )
     }
   },
   {
     dataField: "customer.businessName",
     text: "Cliente",
     sort: true,
   },
   {
     dataField: "serial",
     text: "Seriale",
   },
   {
     dataField: "",
     text: "Opzioni",
     formatter: (cell, row) => {
       return (
         <>
          <ReactTooltip />
          <button className="table-button-cc" style={{opacity: row.online ? '1' : '0.3'}} data-tip={!row.online ? "Offline" : "Shell"} onClick={() => history.push(`/shell/${row.code}`)} disabled={!row.online}><FaTerminal /></button>
          <button className="table-button-cc" data-tip="Vedi" onClick={() => history.push(`/devices/${row.code}`)}><FaEye /></button>
         </>
       )
     }
   }
 ]

 const aggiornamento = async () => {
   try {
     const response = await axios.get(`${process.env.REACT_APP_API}/devices/fetch`, {
       headers: {
           "x-access-token": cookies.jwt
       }
     })
     const customers = await axios.get(`${process.env.REACT_APP_API}/customers/fetch-select`, {
       headers: {
           "x-access-token": cookies.jwt
       }
     })
     viewDevices(response.data)
     viewCustomers(customers.data)
     setLoading(true)
   } catch (e) {
   }
 }

 const [disabledInsert, setDisabledInsert] = useState(false)

 const checkSerial = serial => {
   const fetch = async () => {
     const response = await axios.get(`${process.env.REACT_APP_API}/devices/serial`, {
       params: {
         serial: serial
       },
       headers: {
           "x-access-token": cookies.jwt
       }
     })
     if(response.data.length > 0) {
       setDisabledInsert(true)
       setErr('Il seriale inserito è già associato ad un altro dispositivo.')
     } else {
       setErr("")
       setDisabledInsert(false)
     }
   }
   fetch()
 }


 const addDevice = data => {
   if(selectedCustomer) {
     setErr("")
       const fetch = async () => {
         try {
             const submit = async () => {
               try {
                 const add = await axios.post(`${process.env.REACT_APP_API}/devices/create`, {
                   data: data,
                   customer: selectedCustomer
                 }, {
                   headers: {
                       "x-access-token": cookies.jwt
                   }
                 })
                 toggleAdd()
                 aggiornamento()
               } catch (e) {
                  // setDisabledInsert(true)
                  setErr('Il seriale inserito è già associato ad un altro dispositivo.')
               }
             }
             submit()
         } catch (e) {
         }
       }
       fetch()

   } else {
     setErr("Seleziona un cliente a cui associare il device.")
   }
 }


 const updateDevices = () => {
   const update = async () => {
     const response = await axios.post(`${process.env.REACT_APP_API}/devices/update-system`, {
       data: 'UPDATE'
     }, {
       headers: {
           "x-access-token": cookies.jwt
       }
     })
     toggleUpdate()
     toggleSuccess()
   }
   update()
 }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        {isLoading ? (
                          <ToolkitProvider
                            keyField="_id"
                            data={allDevices}
                            columns={columns}
                            search
                          >
                            {props => (
                              <div>
                                <div className="d-flex flex-row justify-content-between w-100 mb-2">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder="Cerca"
                                    className="leonardo-rounded"
                                  />
                                  <div className="text-sm-right">
                                      <Button
                                        type="button"
                                        className="button-cc-2"
                                        onClick={toggleAdd}
                                        style={{marginRight: '1vw'}}
                                      >
                                        <i className="mdi mdi-plus mr-1" />
                                        Nuovo dispositivo
                                      </Button>
                                      <Button
                                        type="button"
                                        className="button-cc-2"
                                        onClick={toggleUpdate}
                                      >
                                        <MdSystemUpdateAlt className="mr-1"/>
                                        Agent UPDATE
                                      </Button>
                                  </div>
                                </div>
                                <BootstrapTable
                                  {...props.baseProps}
                                  hover
                                  condensed
                                  keyField="ID"
                                  pagination={paginationFactory({
                                    showTotal: false,
                                    firstPageText: "Inizio", // the text of first page button
                                    prePageText: "Prec", // the text of previous page button
                                    nextPageText: "Succ", // the text of next page button
                                    lastPageText: "Fine", // the text of last page button
                                    nextPageTitle: "Vai al successivo", // the title of next page button
                                    prePageTitle: "Vai al precedente", // the title of previous page button
                                    firstPageTitle: "Vai al primo", // the title of first page button
                                    lastPageTitle: "Vai all'ultmo", // the title of last page button
                                  })}

                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        ) : (
                          <center>
                            <img
                              src={Loader}
                              alt="loader"
                            ></img>
                          </center>
                        )}
                      </Col>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Modale per aggiunta device*/}
        <Modal
          isOpen={modalAdd}
        >
        <Form className="form-horizontal"
          onSubmit={handleSubmit(addDevice)}
        >
          <ModalHeader toggle={toggleAdd}>
            <h4>Nuovo dispositivo</h4>
          </ModalHeader>
          <ModalBody>
              <div className="mb-3">
                <Label className="form-label label-cc">Cliente</Label>
                <Select
                  placeholder="Seleziona il cliente"
                  options={allCustomers}
                  styles={selectStyles}
                  onChange={(e) => setSelectCustomer(e)}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label label-cc">Nome dispositivo</Label>
                <input
                  className="form-control"
                  placeholder="Inserisci un nome per il dispositivo"
                  type="text"
                  {... register("name")}
                  required
                />
              </div>
              <div className="mb-3">
                <Label className="form-label label-cc">Seriale</Label>
                <input
                  className="form-control"
                  placeholder="Inserisci il numero seriale"
                  type="text"
                  {... register("serial")}
                  // onChange={e => checkSerial(e.target.value)}
                  required
                />
              </div>
              {err ? <p style={{color: 'crimson'}}>{err}</p> : null}

          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleAdd} className="button-cc-secondary">
              Annulla
            </Button>
            {' '}
            <Button
              color="primary"
              type="submit"
              className="button-cc-2"
              disabled={disabledInsert}
            >
              Aggiungi
            </Button>
          </ModalFooter>
          </Form>

        </Modal>
        {/* Modale per aggiunta device*/}

        {/* Modale per aggiornamento device*/}
        <Modal
          isOpen={modalUpdate}
        >
          <ModalHeader toggle={toggleAdd}>
            <h4>Aggiornamento dispositivi</h4>
          </ModalHeader>
          <ModalBody>
            <p>Attenzione! Stai per aggiornare l&apos;agent dei dispositivi, vuoi procedere?</p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleUpdate} className="button-cc-secondary">
              Annulla
            </Button>
            {' '}
            <Button
              color="primary"
              type="submit"
              className="button-cc-2"
              onClick={() => updateDevices()}
            >
              Continua
            </Button>
          </ModalFooter>
        </Modal>
        {/* Modale per aggiunta device*/}

        {/* Modale per aggiornamento device*/}
        <Modal
          isOpen={modalUpdateSuccess}
        >
          <ModalHeader toggle={toggleSuccess}>
            <h4>Aggiornamento dispositivi</h4>
          </ModalHeader>
          <ModalBody>
            <p>Aggiornamento in corso, i dispositivi scaricheranno in autonomia l&apos;ultima versione dell&apos;agent.</p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              className="button-cc-2"
              onClick={toggleSuccess}
            >
              Continua
            </Button>
          </ModalFooter>
        </Modal>
        {/* Modale per aggiunta device*/}

      </div>
    </React.Fragment>
  )
}

export default Devices
