import React, { useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import logodark from "../../assets/images/logo_dark.png";
import { useForm } from "react-hook-form";
import axios from "axios";

const ForgotPassword = () => {
  const [error, setError] = useState(false);
  const { register, handleSubmit } = useForm();
  const [recoverSuccess, setRecoverSuccess] = useState(true);
  let history = useHistory();

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/auth/forgot-password`, {
        email: data.email.trim(),
      });
      if (response.data.success === true) {
        setRecoverSuccess(false);
        setError(false);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
  };

  const renderForm = () => {
    return (
      <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
        {error && <Alert color="danger">{`Non abbiamo trovato nessun account associato a questa mail.`}</Alert>}
        <div className="mb-3 mt-2">
          <label className="label-cc">Email</label>
          <input
            name="email"
            label="Email"
            className="form-control login-input-field shadows-light"
            placeholder="Inserisci email"
            type="email"
            {...register("email")}
            required
          />
        </div>
        <div className="mt-3 d-grid">
          <button className="button-cc" type="submit">Recupera password</button>
        </div>
      </form>
    );
  };

  return (
    <React.Fragment>
      <div className="background-cc">
        <div className="account-pages pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={6}>
                <Card className="overflow-hidden login-card">
                  <center>
                    <img src={logodark} alt="wirail" style={{ width: "50%" }} className="mt-4 mb-4" />
                  </center>
                  <CardBody className="pt-0">
                    <div className="p-2">
                      {recoverSuccess ? renderForm() : (
                        <div className="mb-3 mt-2">
                          <h3>Password recuperata con successo!</h3>
                          <p>
                            Ti invieremo una mail con le istruzioni per effettuare l&apos;accesso,
                            nel caso non riceva nulla controlla nella <em>casella di spam</em> o
                            scrivi a <a href="mailto:info@connectioncocktails.com" className="a-cc">info@connectioncocktails.com</a>
                          </p>
                        </div>
                      )}
                      <div className="mt-4 text-center">
                        <Link to="/login" className="text-muted login-forget-password" type="submit">
                          <i className="mdi mdi-lock me-1"/>
                          Vai al login
                        </Link>
                      </div>
                    </div>
                    <div className="mt-5 text-center">
                      <p className="login-credit">&copy; {new Date().getFullYear()}{" "}
                        <a href="https://connectioncocktails.com" target="_blank" rel="noreferrer" className="a-cc">Connection Cocktails</a>
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
