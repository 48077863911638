import React, { useEffect, useState } from "react"
import { Link, Redirect, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import {
  Button,
  Form,
  FormGroup,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input
} from "reactstrap"
import Select from 'react-select';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { useForm } from "react-hook-form"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import cellEditFactory from "react-bootstrap-table2-editor"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"

import { FaTerminal, FaEye, FaTrashAlt, FaClipboardList, FaCog } from "react-icons/fa"
import { GrUserManager } from "react-icons/gr"
import { BsTrophy, BsDropletFill, BsKeyFill } from "react-icons/bs"
import { MdAlternateEmail, MdBlock } from "react-icons/md"
import { BiUserCircle, BiWorld } from "react-icons/bi"
import { IoBarcode } from "react-icons/io5"
import { GrSettingsOption } from "react-icons/gr"
import { FiClock, FiTrash2 } from "react-icons/fi"
import ReactTooltip from "react-tooltip"

import ReactApexChart from "react-apexcharts"

import moment from "moment"


import axios from 'axios'
import { parseCookies } from "nookies"
import Loader from '../../assets/images/preloader.gif'

//Funzione controllo permessi
import { checkProperty } from "../../helpers/Permissions/check-permission"

const CustomersProfile = (props) => {
  //GET REQUEST
  const [allCustomers, viewCustomers] = useState({})
  const [allDevices, viewDevices] = useState([{}])
  const [allUsers, viewUsers] = useState([{}])
  const [viewLogs, setLogs] = useState([{}])
  const [viewDrinks, setDrinks] = useState([{}])
  const [viewBoozes, setBoozes] = useState([{}])
  const [viewDispensers, setDispenser] = useState([{}])
  const [viewNames, setNames] = useState([{}])
  const [selectedTab, setSelectedTab] = useState('DEVICES')
  const [isLoading, setLoading] = useState(false)

  const cookies = parseCookies()
  let history = useHistory()


  //Style react Select2
  const selectStyles = {
    control: styles => ({ ...styles, borderRadius: '5px'})
  }

  const { register, handleSubmit, watch, formState: { errors } } = useForm();


  const [pieSeries, setPieSeries] = useState([])
  const [pieLabels, setPieLabels] = useState([])
  const [pieColors, setPieColors] = useState([])
  const [lastDays, setLastDays] = useState([])
  const [lastMonth, setLastMonth] = useState([])
  const [isMax, setMax] = useState(null)
  const [weekSeries, setWeekSeries] = useState([])
  const [weekLabels, setWeekLabels] = useState([])
  const [monthSeries, setMonthSeries] = useState([])
  const [monthLabels, setMonthLabels] = useState([])
  const [topSeries, setTopSeries] = useState([])
  const [topLabels, setTopLabels] = useState([])
  const [top3, setTop3] = useState([{}])
  const [selectedCustomer, setSelectCustomer] = useState(null)
  const [modalBan, setModalBan] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)

  const toggleBan = () => setModalBan(!modalBan)
  const toggleDelete = () => setModalDelete(!modalDelete)



  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/customers/fetch/${props.match.params.id}`, {
          headers: {
              "x-access-token": cookies.jwt
          }
        })
        const devices = await axios.get(`${process.env.REACT_APP_API}/customers/devices`, {
          params: {
            id: props.match.params.id
          },
          headers: {
              "x-access-token": cookies.jwt
          }
        })
        viewDevices(devices.data)
        viewCustomers(response.data)
        viewUsers(response.data.users)
        setLoading(true)

      } catch (e) {
      }
    }
    fetch()
    checkProperty(cookies, history, 'CUSTOMER', props.match.params.id)
  }, [])



  const addDevice = data => {
    const fetch = async () => {
      const response = await axios.patch(`${process.env.REACT_APP_API}/customers/patch`, {
        data: data,
        code: props.match.params.id
      }, {
        headers: {
          "x-access-token": cookies.jwt
        }
      })
      window.location.reload()
    }
    fetch()
  }


  const deleteAccount = () => {
    const fetch = async () => {
      const response = await axios.delete(`${process.env.REACT_APP_API}/customers/delete/${props.match.params.id}`, {
        headers: {
          "x-access-token": cookies.jwt
        }
      })
      history.push("/customers")
    }
    fetch()
  }


  const { SearchBar } = Search
  const columnsDevice = [
    {
      dataField: "name",
      text: "Descrizione",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
           <ReactTooltip />
           <i className="bx bxs-circle" style={{color: row.online ? 'green' : 'red'}} data-tip={row.online ? 'Online' : 'Offline'}/>{" "}
            <span className="a-cc">{row.name}</span>
          </>
        )
      }
    },
    {
      dataField: "customer.businessName",
      text: "Cliente",
      sort: true,
    },
    {
      dataField: "serial",
      text: "Seriale",
    },
    {
      dataField: "",
      text: "Opzioni",
      formatter: (cell, row) => {
        return (
          <>
           <ReactTooltip />
           <button className="table-button-cc" data-tip="Vedi" onClick={() => history.push(`/devices/${row.code}`)}><FaEye /></button>
          </>
        )
      }
    }
  ]

  const columnsUser = [
    {
      dataField: "fname",
      text: "Nome",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
          <ReactTooltip />
          <span>{row.role === "ADMIN" ? <i className="bx bxs-star" style={{color: 'gold'}} data-tip="Admin"/> : <i className="bx bx-user-circle" data-tip="User" />}{" "}{row.fname.toUpperCase() + " " + row.lname.toUpperCase()}</span>
          </>
        )
      }
    },
    {
      dataField: "email",
      text: "Email",
      sort: true
    },
    {
      dataField: "",
      text: "Opzioni",
      formatter: (cell, row) => {
        return (
          <>
           <ReactTooltip />
           <button className="table-button-cc" data-tip="Vedi" onClick={() => history.push(`/users/${row.token}`)}><FaEye /></button>
          </>
        )
      }
    }
  ]



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
                  <React.Fragment>
                    {isLoading ? <Row>
                      <Col md="4" xl="4">
                      <Card>
                        <CardBody>
                          <h2 className="cc-pi-profile">{" "}{allCustomers.businessName}</h2>
                          <br />
                          <ReactTooltip />
                          <p className="cc-pi-info"><i data-tip="Indirizzo" className="cc-pi-info-icon bx bx-map-pin"/> {allCustomers.address.street + ", " + allCustomers.address.civic + " " + allCustomers.address.postalCode + " - " + allCustomers.address.city + " (" + allCustomers.address.region + ")"}</p>
                          <p className="cc-pi-info"><i data-tip="P.IVA / Codice fiscale" className="cc-pi-info-icon bx bx-credit-card"/> {allCustomers.vat}</p>
                          <br />
                          <br />
                          <br />
                        </CardBody>
                        <div className={selectedTab === 'OPZIONI' ? "cc-pi-selected" : "cc-pi-select"} onClick={() => setSelectedTab('OPZIONI')}>
                          <center><FaCog />{" "}OPZIONI</center>
                        </div>
                        <div className={selectedTab === 'DEVICES' ? "cc-pi-selected" : "cc-pi-select"} onClick={() => setSelectedTab('DEVICES')}>
                          <center><i className="bx bxs-dashboard"></i>{" "}DEVICES</center>
                        </div>
                        {cookies.role === 'ADMIN' ? <><div className={selectedTab === 'UTENTI' ? "cc-pi-selected" : "cc-pi-select"} onClick={() => setSelectedTab('UTENTI')}>
                          <center><i className="bx bx-user-circle"></i>{" "}UTENTI</center>
                        </div>
                        <div className={selectedTab === 'ELIMINA' ? "cc-pi-selected" : "cc-pi-select"} onClick={() => setSelectedTab('ELIMINA')}>
                          <center><FaCog />{" "}ELIMINA</center>
                        </div></> : null}
                      </Card>
                      </Col>
                      {/*Grafici dispenser*/}
                      {(() => {
                          switch(selectedTab) {
                            case 'OPZIONI':
                              return (
                                <Col md="8" xl="8">
                                      <Row>
                                        <Card>
                                          <CardBody>
                                          <div className="mb-3">
                                            <Label className="form-label label-cc">API Keys</Label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              defaultValue={allCustomers.apiKey}
                                              disabled
                                            />
                                          </div>
                                          <br />
                                          <Form className="form-horizontal"
                                            onSubmit={handleSubmit(addDevice)}
                                          >

                                          <div className="mb-3">
                                            <Label className="form-label label-cc">Ragione sociale</Label>
                                            <input
                                              className="form-control"
                                              placeholder="es. Mario Rossi SRL"
                                              type="text"
                                              {... register("businessName")}
                                              defaultValue={allCustomers.businessName}
                                              required
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label label-cc">Cod. fiscale / P.IVA</Label>
                                            <input
                                              className="form-control"
                                              placeholder="es. 1234567890"
                                              type="text"
                                              {... register("vat")}
                                              defaultValue={allCustomers.vat}
                                              required
                                            />
                                          </div>
                                          <Row>
                                            <Col md="10">
                                              <div className="mb-3">
                                                <Label className="form-label label-cc">Indirizzo</Label>
                                                <input
                                                  className="form-control"
                                                  placeholder="es. Via Roma"
                                                  type="text"
                                                  {... register("address")}
                                                  defaultValue={allCustomers.address.street}
                                                  required
                                                />
                                              </div>
                                            </Col>
                                            <Col md="2">
                                              <div className="mb-3">
                                                <Label className="form-label label-cc">Civico</Label>
                                                <input
                                                  className="form-control"
                                                  placeholder="es. 1"
                                                  type="text"
                                                  {... register("civic")}
                                                  defaultValue={allCustomers.address.civic}
                                                  required
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md="3">
                                              <div className="mb-3">
                                                <Label className="form-label label-cc">CAP</Label>
                                                <input
                                                  className="form-control"
                                                  placeholder="es. 10100"
                                                  type="text"
                                                  {... register("postalCode")}
                                                  defaultValue={allCustomers.address.postalCode}
                                                  required
                                                />
                                              </div>
                                            </Col>
                                            <Col md="6">
                                              <div className="mb-3">
                                                <Label className="form-label label-cc">Città</Label>
                                                <input
                                                  className="form-control"
                                                  placeholder="es. Roma"
                                                  type="text"
                                                  {... register("city")}
                                                  defaultValue={allCustomers.address.city}
                                                  required
                                                />
                                              </div>
                                            </Col>
                                            <Col md="3">
                                              <div className="mb-3">
                                                <Label className="form-label label-cc">Provincia</Label>
                                                <input
                                                  className="form-control"
                                                  placeholder="es. RM"
                                                  type="text"
                                                  maxlenght="2"
                                                  {... register("region")}
                                                  defaultValue={allCustomers.address.region}
                                                  required
                                                />
                                              </div>
                                            </Col>
                                          </Row>

                                              <Button type="reset" className="button-cc-secondary">
                                                Annulla
                                              </Button>
                                              {' '}
                                              <Button
                                                color="primary"
                                                type="submit"
                                                className="button-cc-2"
                                              >
                                                Modifica
                                              </Button>
                                            </Form>

                                          </CardBody>
                                        </Card>

                                      </Row>
                                </Col>
                              );
                            case 'DEVICES':
                              return (
                                <Col md="8" xl="8">
                                  <Row>
                                    <Card>
                                      <CardBody>
                                      {isLoading ? (
                                        <ToolkitProvider
                                          keyField="_id"
                                          data={allDevices}
                                          columns={columnsDevice}
                                          search
                                        >
                                          {props => (
                                            <div>
                                              <div className="d-flex flex-row justify-content-between w-100 mb-2">
                                                <SearchBar
                                                  {...props.searchProps}
                                                  placeholder="Cerca"
                                                  className="leonardo-rounded"
                                                />
                                              </div>
                                              <BootstrapTable
                                                {...props.baseProps}
                                                hover
                                                condensed
                                                keyField="ID"
                                                pagination={paginationFactory({
                                                  showTotal: false,
                                                  firstPageText: "Inizio", // the text of first page button
                                                  prePageText: "Prec", // the text of previous page button
                                                  nextPageText: "Succ", // the text of next page button
                                                  lastPageText: "Fine", // the text of last page button
                                                  nextPageTitle: "Vai al successivo", // the title of next page button
                                                  prePageTitle: "Vai al precedente", // the title of previous page button
                                                  firstPageTitle: "Vai al primo", // the title of first page button
                                                  lastPageTitle: "Vai all'ultmo", // the title of last page button
                                                })}

                                              />
                                            </div>
                                          )}
                                        </ToolkitProvider>
                                      ) : (
                                        <center>
                                          <img
                                            src={Loader}
                                            alt="loader"
                                          ></img>
                                        </center>
                                      )}
                                      </CardBody>
                                    </Card>
                                  </Row>
                                </Col>
                              )
                            case 'UTENTI':
                              return (
                                <Col md="8" xl="8">
                                  <Row>
                                    <Card>
                                      <CardBody>
                                      {isLoading ? (
                                        <ToolkitProvider
                                          keyField="_id"
                                          data={allUsers}
                                          columns={columnsUser}
                                          search
                                        >
                                          {props => (
                                            <div>
                                              <div className="d-flex flex-row justify-content-between w-100 mb-2">
                                                <SearchBar
                                                  {...props.searchProps}
                                                  placeholder="Cerca"
                                                  className="leonardo-rounded"
                                                />
                                              </div>
                                              <BootstrapTable
                                                {...props.baseProps}
                                                hover
                                                condensed
                                                keyField="ID"
                                                pagination={paginationFactory({
                                                  showTotal: false,
                                                  firstPageText: "Inizio", // the text of first page button
                                                  prePageText: "Prec", // the text of previous page button
                                                  nextPageText: "Succ", // the text of next page button
                                                  lastPageText: "Fine", // the text of last page button
                                                  nextPageTitle: "Vai al successivo", // the title of next page button
                                                  prePageTitle: "Vai al precedente", // the title of previous page button
                                                  firstPageTitle: "Vai al primo", // the title of first page button
                                                  lastPageTitle: "Vai all'ultmo", // the title of last page button
                                                })}

                                              />
                                            </div>
                                          )}
                                        </ToolkitProvider>
                                      ) : (
                                        <center>
                                          <img
                                            src={Loader}
                                            alt="loader"
                                          ></img>
                                        </center>
                                      )}
                                      </CardBody>
                                    </Card>
                                  </Row>
                                </Col>
                              )
                            case 'ELIMINA':
                              return (
                                <Col md="8" xl="8">
                                      <Row>
                                        <Card>
                                          <CardBody>
                                            <p className="a-cc-ban-delete" onClick={() => toggleDelete()}><FiTrash2 />{" "}Clicca qui per eliminare il cliente</p>
                                          </CardBody>
                                        </Card>

                                      </Row>
                                </Col>
                              )
                            default:
                              return null;
                          }
                      })()}
                      {/*Grafici dispenser*/}
                    </Row> : null}
                  </React.Fragment>
            </Col>
          </Row>
        </Container>




        {/* Modale per eliminazione cliente*/}
        <Modal
          isOpen={modalDelete}
        >
          <ModalHeader toggle={toggleDelete}>
            <h4>Elimina</h4>
          </ModalHeader>
          <ModalBody>
            <p>Stai per eliminare il cliente, i suoi utenti e i suoi device, sei sicuro di voler procedere?</p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleDelete} className="button-cc-secondary">
              Annulla
            </Button>
            {' '}
            <Button
              color="primary"
              className="button-cc-2"
              onClick={() => deleteAccount()}
            >
              Prosegui
            </Button>
          </ModalFooter>

        </Modal>
        {/* Modale per connessione device*/}



      </div>
    </React.Fragment>
  )
}

export default CustomersProfile
