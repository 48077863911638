// Authentication
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgotPassword from "../pages/Authentication/ForgotPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import NotFound from "../pages/Dashboard/not.js"

//Devices
import Devices from "../pages/Devices/index.js"
import DevicesProfile from "../pages/Devices/profile.js"
import DevicesShell from "../pages/Devices/shell.js"

//Customers
import Customers from "../pages/Customers/index.js"
import CustomersProfile from "../pages/Customers/profile.js"

//Users
import Users from "../pages/Users/index.js"
import UsersProfile from "../pages/Users/profile.js"

const authProtectedRoutes = [

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: Dashboard },

  //Devices
  { path: "/devices", exact: true, component: Devices },
  { path: "/devices/:id", exact: true, component: DevicesProfile },
  { path: "/shell/:id", exact: true, component: DevicesShell },

  //Customers
  { path: "/customers", exact: true, component: Customers },
  { path: "/customers/:id", exact: true, component: CustomersProfile },

  //Users
  { path: "/users", exact: true, component: Users },
  { path: "/users/:id", exact: true, component: UsersProfile },
]

const publicRoutes = [
  { path: "/login", exact: true, component: Login },
  { path: "/logout", exact: true, component: Logout },
  { path: "/forgot-password", exact: true, component: ForgotPassword },
  { path: "no", exact: true, component: NotFound }
]

export { publicRoutes, authProtectedRoutes }
