import React, { useEffect, useState } from "react"
import { Link, Redirect, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import {
  Button,
  Form,
  FormGroup,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input
} from "reactstrap"
import Select from 'react-select';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { useForm } from "react-hook-form"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import cellEditFactory from "react-bootstrap-table2-editor"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"

import { FaTerminal, FaEye, FaTrashAlt, FaClipboardList, FaCog, FaKey } from "react-icons/fa"
import { GrUserManager } from "react-icons/gr"
import { BsTrophy, BsDropletFill } from "react-icons/bs"
import { MdAlternateEmail, MdBlock } from "react-icons/md"
import { BiUserCircle, BiWorld } from "react-icons/bi"
import { IoBarcode } from "react-icons/io5"
import { GrSettingsOption } from "react-icons/gr"
import { FiClock, FiTrash2 } from "react-icons/fi"
import ReactTooltip from "react-tooltip"

import ReactApexChart from "react-apexcharts"

//Funzione controllo permessi
import { checkPermission, checkProperty } from "../../helpers/Permissions/check-permission"

import moment from "moment"


import axios from 'axios'
import { parseCookies } from "nookies"
import Loader from '../../assets/images/preloader.gif'

const UsersProfile = (props) => {
  //GET REQUEST
  const [allUsers, viewUsers] = useState({})
  const [viewLogs, setLogs] = useState([{}])
  const [viewDrinks, setDrinks] = useState([{}])
  const [viewBoozes, setBoozes] = useState([{}])
  const [viewDispensers, setDispenser] = useState([{}])
  const [viewNames, setNames] = useState([{}])
  const [selectedTab, setSelectedTab] = useState('OPZIONI')
  const [isLoading, setLoading] = useState(false)

  const cookies = parseCookies()
  let history = useHistory()


  //Style react Select2
  const selectStyles = {
    control: styles => ({ ...styles, borderRadius: '5px'})
  }

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { register:register2, handleSubmit:handleSubmit2, formState: { errors:errors2 } } = useForm();


  const [pieSeries, setPieSeries] = useState([])
  const [pieLabels, setPieLabels] = useState([])
  const [pieColors, setPieColors] = useState([])
  const [lastDays, setLastDays] = useState([])
  const [lastMonth, setLastMonth] = useState([])
  const [isMax, setMax] = useState(null)
  const [weekSeries, setWeekSeries] = useState([])
  const [weekLabels, setWeekLabels] = useState([])
  const [monthSeries, setMonthSeries] = useState([])
  const [monthLabels, setMonthLabels] = useState([])
  const [topSeries, setTopSeries] = useState([])
  const [topLabels, setTopLabels] = useState([])
  const [top3, setTop3] = useState([{}])
  const [allCustomers, viewCustomers] = useState([{}])
  const [selectedCustomer, setSelectCustomer] = useState(null)
  const [modalBan, setModalBan] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)

  const toggleBan = () => setModalBan(!modalBan)
  const toggleDelete = () => setModalDelete(!modalDelete)



  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/users/fetch/${props.match.params.id}`, {
          headers: {
              "x-access-token": cookies.jwt
          }
        })
        const customers = await axios.get(`${process.env.REACT_APP_API}/customers/fetch-select`, {
          headers: {
              "x-access-token": cookies.jwt
          }
        })

        viewUsers(response.data)
        viewCustomers(customers.data)
        setSelectCustomer({
          value: response.data.code,
          label: response.data.customer
        })
        setLoading(true)

      } catch (e) {
      }
    }
    fetch()
    checkProperty(cookies, history, 'USER', props.match.params.id)

  }, [])



  const addDevice = data => {
    const fetch = async () => {
      const response = await axios.patch(`${process.env.REACT_APP_API}/users/patch`, {
        customer: selectedCustomer,
        data: data,
        role: allUsers.role,
        token: allUsers.token,
        email: allUsers.email
      }, {
        headers: {
          "x-access-token": cookies.jwt
        }
      })
      window.location.reload()
    }
    fetch()
  }

  const [errPwd, setErrPwd] = useState("")

  const changePassword = data => {
    if(data.password === data.r_password) {
      setErrPwd("")
      const fetch = async () => {
        const response = await axios.patch(`${process.env.REACT_APP_API}/users/password`, {
          email: allUsers.email,
          data: data,
        }, {
          headers: {
            "x-access-token": cookies.jwt
          }
        })

        window.location.reload()
      }
      fetch()
    } else {
      setErrPwd("Le password inserite non coincidono.")
    }

  }


  const banAccount = () => {
    const fetch = async () => {
      const response = await axios.patch(`${process.env.REACT_APP_API}/users/ban`, {
        customer: selectedCustomer,
        role: allUsers.role,
        token: allUsers.token,
        email: allUsers.email,
        enabled: !allUsers.enabled
      }, {
        headers: {
          "x-access-token": cookies.jwt
        }
      })
      window.location.reload()
    }
    fetch()
  }

  const deleteAccount = () => {
    const fetch = async () => {
      const response = await axios.patch(`${process.env.REACT_APP_API}/users/delete`, {
        customer: selectedCustomer,
        role: allUsers.role,
        token: allUsers.token,
        email: allUsers.email,
      }, {
        headers: {
          "x-access-token": cookies.jwt
        }
      })
      history.push("/users")
    }
    fetch()
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
                  <React.Fragment>
                    {isLoading ? <Row>
                      <Col md="4" xl="4">
                      <Card>
                        <CardBody>
                          <h2 className="cc-pi-profile">{allUsers.enabled ? <i className="bx bxs-circle" data-tip="Abilitato" style={{color: 'green'}}/> : <MdBlock data-tip="Bannato"/>}{" "}{allUsers.fname + " " + allUsers.lname}</h2>
                          <br />
                          <ReactTooltip />
                          <p className="cc-pi-info"><i data-tip="Cliente" className="cc-pi-info-icon bx bxs-user-pin"/> {allUsers.customer ? <Link to={`/customers/${allUsers.code}`} className="a-cc">{allUsers.customer}</Link> : 'N/A'}</p>
                          <p className="cc-pi-info"><MdAlternateEmail data-tip="Email" className="cc-pi-info-icon"/> {allUsers.email}</p>
                          <br />
                          <br />
                          <br />
                        </CardBody>
                        <div className={selectedTab === 'OPZIONI' ? "cc-pi-selected" : "cc-pi-select"} onClick={() => setSelectedTab('OPZIONI')}>
                          <center><FaCog />{" "}OPZIONI</center>
                        </div>
                        <div className={selectedTab === 'PASSWORD' ? "cc-pi-selected" : "cc-pi-select"} onClick={() => setSelectedTab('PASSWORD')}>
                          <center><FaKey />{" "}CAMBIA PASSWORD</center>
                        </div>
                        {cookies.role === 'ADMIN' ? <div className={selectedTab === 'ELIMINA' ? "cc-pi-selected" : "cc-pi-select"} onClick={() => setSelectedTab('ELIMINA')}>
                          <center><FaCog />{" "}ELIMINA</center>
                        </div> : null}
                      </Card>
                      </Col>
                      {/*Grafici dispenser*/}
                      {(() => {
                          switch(selectedTab) {
                            case 'OPZIONI':
                              return (
                                <Col md="8" xl="8">
                                      <Row>
                                        <Card>
                                          <CardBody>
                                          <Form className="form-horizontal"
                                            onSubmit={handleSubmit(addDevice)}
                                          >
                                                <Row>
                                                  <Col md="6">
                                                    <div className="mb-3">
                                                      <Label className="form-label label-cc">Nome</Label>
                                                      <input
                                                        className="form-control"
                                                        placeholder="Inserisci descrizione"
                                                        type="text"
                                                        defaultValue={allUsers.fname}
                                                        {... register("fname")}
                                                        required
                                                      />
                                                    </div>
                                                  </Col>
                                                  <Col md="6">
                                                  <div className="mb-3">
                                                    <Label className="form-label label-cc">Cognome</Label>
                                                    <input
                                                      className="form-control"
                                                      placeholder="Inserisci seriale"
                                                      type="text"
                                                      defaultValue={allUsers.lname}
                                                      {... register("lname")}
                                                      required
                                                    />
                                                  </div>
                                                  </Col>
                                                </Row>
                                                <div className="mb-3">
                                                  <Label className="form-label label-cc">Email</Label>
                                                  <input
                                                    className="form-control"
                                                    placeholder="Inserisci seriale"
                                                    type="email"
                                                    defaultValue={allUsers.email}
                                                    disabled
                                                  />
                                                </div>



                                              <Button type="reset" className="button-cc-secondary">
                                                Annulla
                                              </Button>
                                              {' '}
                                              <Button
                                                color="primary"
                                                type="submit"
                                                className="button-cc-2"
                                              >
                                                Modifica
                                              </Button>
                                            </Form>

                                          </CardBody>
                                        </Card>

                                      </Row>
                                </Col>
                              );
                            case 'PASSWORD':
                              return (
                                <Col md="8" xl="8">
                                      <Row>
                                        <Card>
                                          <CardBody>
                                          <Form className="form-horizontal"
                                            onSubmit={handleSubmit2(changePassword)}
                                          >
                                                <Row>
                                                  <Col md="6">
                                                    <div className="mb-3">
                                                      <Label className="form-label label-cc">Nuova password</Label>
                                                      <input
                                                        className="form-control"
                                                        type="password"
                                                        minLength="5"
                                                        {... register2("password")}
                                                        required
                                                      />
                                                    </div>
                                                  </Col>
                                                  <Col md="6">
                                                  <div className="mb-3">
                                                    <Label className="form-label label-cc">Ripeti nuova password</Label>
                                                    <input
                                                      className="form-control"
                                                      type="password"
                                                      minLength="5"
                                                      {... register2("r_password")}
                                                      required
                                                    />
                                                  </div>
                                                  </Col>
                                                </Row>

                                                {errPwd ? <p style={{color: "crimson"}} > {errPwd}</p> : null}


                                              <Button type="reset" className="button-cc-secondary">
                                                Annulla
                                              </Button>
                                              {' '}
                                              <Button
                                                color="primary"
                                                type="submit"
                                                className="button-cc-2"
                                              >
                                                Modifica
                                              </Button>
                                            </Form>

                                          </CardBody>
                                        </Card>

                                      </Row>
                                </Col>
                              );
                            case 'ELIMINA':
                              return (
                                <Col md="8" xl="8">
                                      <Row>
                                        <Card>
                                          <CardBody>
                                            <p className="a-cc-ban-delete" onClick={() => toggleBan()}><MdBlock />{" "}Clicca qui per {allUsers.enabled ? 'bloccare' : 'sbloccare'} l&apos;account</p>
                                            <p className="a-cc-ban-delete" onClick={() => toggleDelete()}><FiTrash2 />{" "}Clicca qui per eliminare l&apos;account</p>
                                          </CardBody>
                                        </Card>

                                      </Row>
                                </Col>
                              )
                            default:
                              return null;
                          }
                      })()}
                      {/*Grafici dispenser*/}
                    </Row> : null}
                  </React.Fragment>
            </Col>
          </Row>
        </Container>


        {/* Modale per connessione device*/}
        <Modal
          isOpen={modalBan}
        >
          <ModalHeader toggle={toggleBan}>
            <h4>Ban</h4>
          </ModalHeader>
          <ModalBody>
            <p>Stai per {allUsers.enabled ? 'eseguire' : 'rimuovere'} il ban temporaneo dell&apos;utente, sei sicuro di voler procedere?</p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleBan} className="button-cc-secondary">
              Annulla
            </Button>
            {' '}
            <Button
              color="primary"
              className="button-cc-2"
              onClick={() => banAccount()}
            >
              Prosegui
            </Button>
          </ModalFooter>

        </Modal>
        {/* Modale per connessione device*/}

        {/* Modale per connessione device*/}
        <Modal
          isOpen={modalDelete}
        >
          <ModalHeader toggle={toggleDelete}>
            <h4>Elimina</h4>
          </ModalHeader>
          <ModalBody>
            <p>Stai per eliminare l&apos;utente, sei sicuro di voler procedere?</p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleDelete} className="button-cc-secondary">
              Annulla
            </Button>
            {' '}
            <Button
              color="primary"
              className="button-cc-2"
              onClick={() => deleteAccount()}
            >
              Prosegui
            </Button>
          </ModalFooter>

        </Modal>
        {/* Modale per connessione device*/}



      </div>
    </React.Fragment>
  )
}

export default UsersProfile
