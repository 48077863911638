import { Col, Container, Form, Row, Input, Label } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { setCookie } from "nookies";
import { useForm } from "react-hook-form";
import axios from "axios";
import CarouselPage from "./CarouselPage";
import logodark from "../../assets/images/logo_dark.png";
import React, { useState } from "react";

const ONE_MONTH_IN_SECONDS = 2592000; // 30 * 24 * 60 * 60

const Login2 = () => {
  // Form validation
  const [err, setErr] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  const onSubmit = (data) => {
    const onSubmit = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/auth/login`,
          {
            email: data.email,
            password: data.password,
          }
        );
        setErr("");
        localStorage.setItem("jwt", response.data.token);
        setCookie(null, "email", response.data.email, {
          maxAge: ONE_MONTH_IN_SECONDS,
          path: "/",
        });
        setCookie(null, "jwt", response.data.token, {
          maxAge: ONE_MONTH_IN_SECONDS,
          path: "/",
        });
        setCookie(null, "name", response.data.name, {
          maxAge: ONE_MONTH_IN_SECONDS,
          path: "/",
        });
        setCookie(null, "_id", response.data.userID, {
          maxAge: ONE_MONTH_IN_SECONDS,
          path: "/",
        });
        setCookie(null, "role", response.data.role, {
          maxAge: ONE_MONTH_IN_SECONDS,
          path: "/",
        });
        if (response.data.role === "USER") {
          setCookie(null, "code", response.data.code, {
            maxAge: ONE_MONTH_IN_SECONDS,
            path: "/",
          });
          history.push(`/customers/${response.data.code}`);
        } else if (response.data.role === "ADMIN") {
          history.push("/");
        }
      } catch (e) {
        setErr("Nome utente o password errati.");
      }
    };
    onSubmit();
  };

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <Row className="g-0">
          <CarouselPage />

          <Col xl={4}>
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="my-auto">
                    <center>
                      <img
                        src={logodark}
                        alt=""
                        height="69"
                        className="auth-logo-dark"
                      />
                    </center>

                    <div className="mt-4">
                      <Form
                        className="form-horizontal"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="mb-3">
                          <Label className="form-label label-cc">Email</Label>
                          <input
                            name="email"
                            className="form-control"
                            placeholder="Inserisci la tua email"
                            type="email"
                            {...register("email")}
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label label-cc">
                            Password
                          </Label>
                          <input
                            name="password"
                            type="password"
                            className="form-control "
                            placeholder="Inserisci la tua password"
                            {...register("password")}
                            required
                          />
                        </div>

                        <div className="form-check">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="auth-remember-check"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Ricordami
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <button className="button-cc" type="submit">
                            Accedi
                          </button>
                        </div>
                        <div style={{ marginTop: "1vh" }}>
                          <Link to="/forgot-password" className="a-cc">
                            Password dimenticata?
                          </Link>{" "}
                        </div>
                        {err ? (
                          <>
                            <br />
                            <span
                              style={{ color: "crimson", marginTop: "1vh" }}
                            >
                              {err}
                            </span>
                          </>
                        ) : null}
                      </Form>
                    </div>
                  </div>

                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()}{" "}
                      <a
                        href="https://connectioncocktails.com"
                        target="_blank"
                        className="a-cc"
                        rel="noreferrer noopener"
                      >
                        Connection Cocktails
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Login2;
